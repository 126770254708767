<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" :max-width="new_or_search=='search'?1100:900" overlay-opacity="0.9" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<b v-html="cdialog_title"></b>
				<v-spacer/>
				<div class="mr-4" v-show="search_results.length"><v-btn dark :text="!at_least_one_item_selected" :color="at_least_one_item_selected?'primary':'#999'" @click="add_selected_items"><v-icon v-show="at_least_one_item_selected" small class="mr-2">fas fa-check</v-icon>Add selected item{{max_items!=1?'s':''}}</v-btn></div>
				<v-btn color="secondary" @click="done_clicked">Done<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
			</v-card-title>
			<v-card-text class="pt-3" style="font-size:16px">
				<!-- Search/Create toggle -->
				<div class="my-1 d-flex"><v-spacer/>
					<v-btn-toggle dense active-class="k-toggle-btn-active-class indigo darken-4" class="k-toggle-btn" v-model="new_or_search" mandatory>
						<v-btn style="width:240px" light class="k-nocaps-btn k-tight-btn" value="search" @click.stop=""><v-icon small :color="new_or_search=='search'?'white':'black'" class="mr-2">fas fa-search</v-icon>Search EXISTING</v-btn>
						<v-btn light class="k-nocaps-btn k-tight-btn" value="shared" @click.stop=""><v-icon small :color="new_or_search=='shared'?'white':'black'" class="mr-2">fas fa-share</v-icon>Import SHARED</v-btn>
						<v-btn light class="k-nocaps-btn k-tight-btn" value="new" @click.stop=""><v-icon small :color="new_or_search=='new'?'white':'black'" class="mr-2">fas fa-plus</v-icon>Create NEW</v-btn>
					</v-btn-toggle>
				<v-spacer/></div>

				<!-- Create interface -->
				<div v-show="new_or_search=='new'">
					<div v-if="citem_types.length>1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<b style="font-size:20px">Create New:</b>
						<v-btn v-if="citem_types.includes('lessons')" class="ml-3" dark color="blue darken-3" @click="create_lesson_start"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Lesson</span></v-btn>
						<v-btn v-if="citem_types.includes('activities')" class="ml-3" dark color="deep-purple darken-3" @click="create_activity_start"><v-icon small class="mr-2">fas fa-star</v-icon>{{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types.includes('resources')" class="ml-3" dark color="teal darken-3" @click="create_new_resource"><v-icon small class="mr-2">fas fa-link</v-icon>Other Resource</v-btn>
					<v-spacer/></div>
					<div v-if="citem_types.length==1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<v-btn v-if="citem_types[0]=='lessons'" dark color="blue darken-3" @click="create_lesson_start"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Create New Lesson</span></v-btn>
						<v-btn v-if="citem_types[0]=='activities'" dark color="deep-purple darken-3" @click="create_activity_start"><v-icon small class="mr-2">fas fa-star</v-icon>Create New {{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types[0]=='resources'" dark color="teal darken-3" @click="create_new_resource"><v-icon small class="mr-2">fas fa-link</v-icon>Create New Resource</v-btn>
					<v-spacer/></div>
					<div>
						<div v-for="(sr, i) in created_items" :key="sr.item_id">
							<div v-if="i==0" class="k-full-resource-search-result-category">
								<b>Created Items ({{created_items.length}})</b>
							</div>
							<v-hover v-slot:default="{hover}"><div class="k-full-resource-search-result" :class="sr.already_added?'k-full-resource-search-result-already-added':''">
								<div><v-checkbox class="shrink mt-0 pt-0 d-inline-block" :disabled="sr.already_added" hide-details v-model="sr.selected"></v-checkbox></div>
								<div style="width:36px;" class="text-center"><v-icon style="font-size:20px; margin-top:-2px" class="mr-2" :color="(sr.selected&&!sr.already_added)?'primary':'#666'">{{sr.value.icon()}}</v-icon></div>
								<div><span v-html="sr.text"></span> <v-btn v-show="hover||sr==last_previewed_item" x-small text color="primary" @click="preview_item(sr)">Preview<v-icon small class="ml-1">fas fa-arrow-up-right-from-square</v-icon></v-btn></div>
								<!-- <div v-html="sr.item_id"></div> -->
							</div></v-hover>
						</div>
					</div>
					<v-hover v-slot:default="{hover}" v-if="show_alba_btn"><div style="position:absolute; right:104px; bottom:4px; background-color:#ccc; font-size:14px; line-height:14px; padding:4px; border-radius:6px; cursor:pointer" :style="'opacity:'+(hover?'0.5':'0')" @click="alba_showing=true">ALBA</div></v-hover>
					<v-hover v-slot:default="{hover}" v-if="show_gavs_btn"><div style="position:absolute; right:4px; bottom:4px; background-color:#ccc; font-size:14px; line-height:14px; padding:4px; border-radius:6px; cursor:pointer" :style="'opacity:'+(hover?'0.5':'0')" @click="bulk_activity_editor_showing=true">GAVS IMPORT</div></v-hover>
				</div>

				<!-- Import interface -->
				<div v-show="new_or_search=='shared'">
					<div v-if="citem_types.length>1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<b style="font-size:20px">Import Shared:</b>
						<v-btn v-if="citem_types.includes('lessons')" class="ml-3" dark color="blue darken-3" @click="import_shared_item('lesson')"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Lesson</span></v-btn>
						<v-btn v-if="citem_types.includes('activities')" class="ml-3" dark color="deep-purple darken-3" @click="import_shared_item('activity')"><v-icon small class="mr-2">fas fa-star</v-icon>{{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types.includes('resources')" class="ml-3" dark color="teal darken-3" @click="import_shared_item('resource')"><v-icon small class="mr-2">fas fa-link</v-icon>Other Resource</v-btn>
					<v-spacer/></div>
					<div v-if="citem_types.length==1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<v-btn v-if="citem_types[0]=='lessons'" dark color="blue darken-3" @click="import_shared_item('lesson')"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Import Shared Lesson</span></v-btn>
						<v-btn v-if="citem_types[0]=='activities'" dark color="deep-purple darken-3" @click="import_shared_item('activity')"><v-icon small class="mr-2">fas fa-star</v-icon>Import Shared {{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types[0]=='resources'" dark color="teal darken-3" @click="import_shared_item('resource')"><v-icon small class="mr-2">fas fa-link</v-icon>Import Shared Resource</v-btn>
					<v-spacer/></div>
					<div>
						<div v-for="(sr, i) in imported_items" :key="sr.item_id">
							<div v-if="i==0" class="k-full-resource-search-result-category">
								<b>Imported Items ({{imported_items.length}})</b>
							</div>
							<v-hover v-slot:default="{hover}"><div class="k-full-resource-search-result" :class="sr.already_added?'k-full-resource-search-result-already-added':''">
								<div><v-checkbox class="shrink mt-0 pt-0 d-inline-block" :disabled="sr.already_added" hide-details v-model="sr.selected"></v-checkbox></div>
								<div style="width:36px;" class="text-center"><v-icon style="font-size:20px; margin-top:-2px" class="mr-2" :color="(sr.selected&&!sr.already_added)?'primary':'#666'">{{sr.value.icon()}}</v-icon></div>
								<div><span v-html="sr.text"></span> <v-btn v-show="hover||sr==last_previewed_item" x-small text color="primary" @click="preview_item(sr)">Preview<v-icon small class="ml-1">fas fa-arrow-up-right-from-square</v-icon></v-btn></div>
								<!-- <div v-html="sr.item_id"></div> -->
							</div></v-hover>
						</div>
					</div>
				</div>

				<!-- Search interface -->
				<div v-show="new_or_search=='search'">
					<div class="my-4 d-flex align-center">
						<v-spacer/>
						<v-btn-toggle v-if="citem_types.length>1" class="mr-3" color="indigo darken-4" v-model="selected_resource_search_types" multiple>
							<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="citem_types.includes('lessons')" v-on="on" small><v-icon small :color="search_lessons?'indigo darken-4':'indigo lighten-2'">fas fa-rectangle-list</v-icon></v-btn></template>Search for Lesson Plans</v-tooltip>
							<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="citem_types.includes('activities')" v-on="on" small><v-icon small :color="search_activities?'indigo darken-4':'indigo lighten-2'">fas fa-star</v-icon></v-btn></template>Search for {{site_config.sparkl_app_name}} Student Activities</v-tooltip>
							<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="citem_types.includes('resources')" v-on="on" small><v-icon small :color="search_resources?'indigo darken-4':'indigo lighten-2'">fas fa-link</v-icon></v-btn></template>Search for other Resources</v-tooltip>
						</v-btn-toggle>
						
						<div style="flex:0 0 auto; width:700px; max-width:calc(100vw - 40px)">
							<v-text-field light rounded outlined solo hide-details clearable dense
								placeholder="Search"
								v-model="search_terms"
								prepend-inner-icon="fas fa-search" @click:prepend-inner="initiate_search_from_keyboard_or_search_icon"
								@click:clear="execute_search_clear"
								@keyup="search_field_keyup"
							></v-text-field>
						</div>

						<v-spacer/>
					</div>
					<div class="d-flex mb-4"><v-spacer/>
						<v-menu offset-x right v-model="limit_menu_showing"><template v-slot:activator="{on}"><v-btn v-on="on" small class="mr-3" color="indigo darken-3" dark><v-icon small class="mr-1">fas fa-filter</v-icon>Search In…</v-btn></template>
							<v-list min-width="450" dense>
								<v-list-item v-if="home_lesson&&allow_add_from_home" @click.stop="selected_home_lesson=!selected_home_lesson"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_home_lesson?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_home_lesson?'#fff':''" small>fas fa-rectangle-list</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_home_lesson?'font-weight:bold':''" :class="selected_home_lesson?'indigo--text text--darken-3':''">Resources tagged to this lesson</v-list-item-title></v-list-item>
								<v-list-item @click.stop="select_none"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="no_filters?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="no_filters?'#fff':''" small>fas fa-globe</v-icon></span></v-list-item-icon><v-list-item-title :style="no_filters?'font-weight:bold':''" :class="no_filters?'indigo--text text--darken-3':''">All items in {{site_config.app_name}}</v-list-item-title></v-list-item>
								<!-- can't add from my_default_collection TO my_default_collection -->
								<v-list-item v-if="home_collection!=my_default_collection" @click.stop="selected_my=!selected_my"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_my?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_my?'#fff':''" small>fas fa-cubes-stacked</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_my?'font-weight:bold':''" :class="selected_my?'indigo--text text--darken-3':''">{{site_config.default_my_collection_label}}</v-list-item-title></v-list-item>
								<v-list-item v-if="home_collection&&allow_add_from_home" @click.stop="selected_home_collection=!selected_home_collection"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_home_collection?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_home_collection?'#fff':''" small>{{home_collection.collection_icon()}}</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_home_collection?'font-weight:bold':''" :class="selected_home_collection?'indigo--text text--darken-3':''">This content collection (“{{home_collection.title}}”)</v-list-item-title></v-list-item>
								<v-list-item @click.stop=""><v-list-item-icon v-if="selected_course" style="margin-top:12px"><span :class="selected_course?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_course?'#fff':''" small>{{selected_course.collection_icon()}}</v-icon></span></v-list-item-icon><v-list-item-title><div class="mt-2">
									<v-autocomplete clearable v-model="selected_course" :items="course_selection_array" label="Choose a Course, Repository, or Collection" outlined hide-details dense background-color="#fff" @change=""></v-autocomplete>
								</div></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>

						<div v-if="selected_home_lesson" style="margin-top:4px;font-size:18px"><i>Searching in <b>resources tagged to this lesson</b></i></div>
						<div v-if="selected_home_collection" style="margin-top:4px;font-size:18px"><i>Searching in <b>resources tagged to this content collection</b> ({{home_collection.title}})</i></div>
						<div v-if="selected_my" style="margin-top:4px;font-size:18px"><i>Searching in <b>{{site_config.default_my_collection_label}}</b></i></div>
						<div v-if="selected_course" style="margin-top:4px;font-size:18px"><i>Searching in content collection <b>{{selected_course.title}}</b></i></div>
						<div v-if="selected_repository" style="margin-top:4px;font-size:18px"><i>Searching in repository <b>{{selected_repository.title}}</b></i></div>
						<div v-if="no_filters" style="margin-top:4px">
							<i style="font-size:18px">Searching <b>all items in {{site_config.app_name}}</b></i><span v-if="last_search_terms" class="ml-3">({{last_search_terms!=search_terms?'original ':''}}search terms: “{{last_search_terms}}”)</span>
							<div v-if="no_filters_search_help" class="mt-2"><i v-html="no_filters_search_help"></i></div>
						</div>
					<v-spacer/></div>

					<div v-if="((search_term_res.length>0&&searchable_items.length>0)||last_search_terms)&&search_results.length==0" class="my-2 k-resource-search-results k-resource-search-results-no-results">No items match your search terms.</div>

					<div>
						<div v-if="paginated_results.length > 0" :class="selected_my && 'd-flex k-full-resource-search-sort-and-pagination-container'">
							<div v-if="selected_my">
								<div style="font-size:16px" class="mr-2">Sort by:</div>
								<div>
									<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="resource_search_sort_by" mandatory>
										<v-btn x-small width="90px" light :value="'title'" @click.stop="resource_search_sort_by = 'title'">Title</v-btn>
										<v-btn x-small width="90px" light :value="'created_at'" @click.stop="set_resource_search_sort_order">Date Created</v-btn>
									</v-btn-toggle>
								</div>
							</div>
							<v-pagination :class="selected_my && 'k-full-resource-search-sort-and-pagination-container__pagination'" v-model="current_page" :length="total_pages"></v-pagination>
						</div>
						<div v-for="(sr, i) in paginated_results" :key="sr.item_id">
							<div v-if="sr.category=='1lesson'&&(i==0||paginated_results[i-1].category!='1lesson')" class="k-full-resource-search-result-category">
								<b>Lessons ({{category_count['1lesson'].length}})</b>
								<span v-if="no_filters&&server_search_results.counts.lessons>db_query_offset + 50" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn>
								<!-- <span v-if="no_filters" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn> -->
									<!-- <i>({{server_search_results.counts.lessons}} total lessons match search terms)</i> -->
								</span>
							</div>
							<div v-if="sr.category=='2sparkl'&&(i==0||paginated_results[i-1].category!='2sparkl')" class="k-full-resource-search-result-category">
								<b>{{site_config.sparkl_app_name}} Student Activities ({{category_count['2sparkl'].length}})</b>
								<span v-if="no_filters&&server_search_results.counts.activities>db_query_offset + 50" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn>
								<!-- <span v-if="no_filters" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn> -->
									<!-- <i>({{server_search_results.counts.activities}} total activities match search terms)</i> -->
								</span>
							</div>
							<div v-if="sr.category=='3resource'&&(i==0||paginated_results[i-1].category!='3resource')" class="k-full-resource-search-result-category">
								<b>{{i>0?'Other':''}} Resources ({{category_count['3resource'].length}})</b>
								<span v-if="no_filters&&server_search_results.counts.resources>db_query_offset + 50" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn>
								<!-- <span v-if="no_filters" class="ml-4"><v-btn x-small text color="primary" @click="show_more">Show more</v-btn> -->
									<!-- <i>({{server_search_results.counts.resources}} total resources match search terms)</i> -->
								</span>
							</div>
							<v-hover v-slot:default="{hover}"><div class="k-full-resource-search-result" :class="result_class(sr, hover)">
								<div><v-checkbox class="shrink mt-0 pt-0 d-inline-block" :disabled="sr.already_added" hide-details v-model="sr.selected" @change="item_checked(sr)"></v-checkbox></div>
								<div style="width:36px;" class="text-center"><v-icon style="font-size:20px; margin-top:-2px" class="mr-2" :color="(sr.selected&&!sr.already_added)?'primary':'#666'">{{sr.value.icon()}}</v-icon></div>
								<div><span v-html="sr.text"></span><i v-show="sr.already_added" class="ml-2">(item already included)</i><v-btn v-show="hover||sr==last_previewed_item" text x-small color="primary" class="ml-2" @click="preview_item(sr)">Preview<v-icon small class="ml-1">fas fa-arrow-up-right-from-square</v-icon></v-btn></div>
								<v-spacer/>
								<div v-if="$store.state.show_resource_search_ids" class="ml-2 grey lighten-4 px-2" style="font-style: italic; font-size:12px" v-html="sr.item_id"></div>
								<div v-if="selected_my" class="k-resource-collection-item-created-date" v-html="created_date(sr)"></div>
							</div></v-hover>
						</div>
					</div>
				</div>

			</v-card-text>
		</v-card>

		<ResourceEditor v-if="new_resource_placeholder" :original_resource="new_resource_placeholder" :course="this.home_collection"
			@edit_resource_cancel="create_resource_cancel"
			@edit_resource_saved="create_resource_saved"
		/>

		<v-dialog v-if="previewed_lesson" v-model="previewed_lesson" max-width="900" persistent scrollable content-class="k-resource-collection-item-lesson-card-dialog">
			<v-card class="k-resource-collection-item-lesson-card">
				<div class="d-flex align-center pb-2" style="border-bottom:1px solid #999;">
					<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
					<div class="k-lesson-title" style="font-weight:bold" v-html="previewed_lesson.lesson_title"></div>
				</div>
				<LessonView :lesson="previewed_lesson" />
				<v-card-actions class="px-1 pt-2 pb-0" style="border-top:1px solid #999;">
					<v-spacer/>
					<v-btn color="secondary" dark @click="previewed_lesson=false"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-if="new_lesson" v-model="new_lesson" :max-width="use_enhanced_lesson_editor?1100:900" persistent scrollable :retain-focus="false" content-class="k-resource-collection-item-lesson-card-dialog k-lpe-editor-dialog">
			<v-card class="k-resource-collection-item-lesson-card">
				<div class="d-flex">
					<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
					<div class="k-lesson-title" style="font-weight:bold">New Lesson</div>
					<v-spacer/>
					<v-checkbox v-if="enable_llm_lesson_plans" class="mt-0 pt-0" v-model="use_llm_lesson_plans" @click="use_llm_clicked" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"><template v-slot:label><span style="color:#444">Use Lesson Plan Companion <v-icon class="k-lpe-dog-icon" style="margin-top:-5px">fas fa-dog</v-icon> (<b class="red--text text--darken-3">BETA</b>)</span></template></v-checkbox>
				</div>
				<div :class="(use_enhanced_lesson_editor)?'k-lpe-lesson-edit-outer':'k-lesson-edit-outer'">
					<LessonEditorEnhanced v-if="use_enhanced_lesson_editor" :original_lesson="new_lesson" :lesson_class="new_lesson_class" :course_code="new_lesson_course_code" :lp_unit_id="new_lesson_lp_unit_id" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
					<LessonEditor v-else :original_lesson="new_lesson" :lesson_class="new_lesson_class" :course_code="new_lesson_course_code" :lp_unit_id="new_lesson_lp_unit_id" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
				</div>
			</v-card>
		</v-dialog>

		<BulkActivityImport v-if="bulk_activity_editor_showing" :unit="home_unit" :collection="home_collection" @dialog_close="bulk_activity_editor_showing=false"></BulkActivityImport>
		<AILessonBuilder v-if="alba_showing" :unit="home_unit" :collection="home_collection" @dialog_close="alba_showing=false"></AILessonBuilder>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceEditor from '../resources/ResourceEditor'
import LessonView from '../lessons/LessonView'
import LessonEditor from '../lessons/LessonEditor'
import LessonEditorEnhanced from '../lessons/LessonEditorEnhanced'
import BulkActivityImport from './BulkActivityImport'
import AILessonBuilder from './AILessonBuilder'

export default {
	name: 'ResourceSearch',
	components: { ResourceEditor, LessonView, LessonEditor, LessonEditorEnhanced, BulkActivityImport, AILessonBuilder },
	props: {
		// req: { type: String, required: true },
		dialog_title: { type: String, required: false, default() { return 'Search Lessons, Activities, and other Resources'} },
		item_types: { required: false, default() { return ['resources','lessons','activities']} },
		existing_resources: { required: false, default() { return [] } },
		existing_lessons: { required: false, default() { return [] } },
		existing_activities: { required: false, default() { return [] } },
		home_lesson: { type: Object, required: false, default() { return null } },
		home_unit: { type: Object, required: false, default() { return null } },
		home_collection: { type: Object, required: false, default() { return null } },
		// MC: No longer necessary?
		allow_add_from_home: { type: Boolean, required: false, default() { return false } },
		max_items: { required: false, default() { return 0 }},	// 0 = no max; 1 = choose one
		adding_to_lesson: { required: false, default() { return false }},	// send true when we launch from LessonEditor
	},
	data() { return {
		dialog_open: true,

		search_terms: '',
		last_search_terms: '',
		search_term_res: [],
		stop_words: [],

		searchable_items: [],
		search_results: [],
		server_search_results: { resources:[], activities:[], lessons:[], counts: {resources:0, activities:0, lessons:0}},
		no_filter_search_text: '',
		category_count: {'1lesson':[], '2sparkl':[], '3resource':[]},

		selected_home_lesson: false,
		selected_home_collection: false,
		selected_my: false,
		selected_course: '',
		selected_repository: '',

		previewed_lesson: null,
		new_lesson: null,
		new_lesson_class: '',
		new_lesson_course_code: '',
		new_lesson_lp_unit_id: 0,

		open_sparkl_activity_embed_object: null,
		sparkl_closed_from_embed: false,
		new_activity: null,
		new_activity_saved: false,

		limit_menu_showing: false,

		search_result_icons: {
			resource: 'fas fa-link',
			activity: 'fas fa-star',
			lesson: 'fas fa-rectangle-list',
		},

		last_previewed_item: null,

		created_items: [],
		imported_items: [],
		new_resource_placeholder: null,

		current_page: 1,
		items_per_page: 25,
		db_query_offset: 0,

		bulk_activity_editor_showing: false,
		alba_showing: false,
	}},
	computed: {
		...mapState(['user_info', 'site_config', 'all_courses_loaded', 'all_courses', 'lst', 'added_my_courses']),
		...mapGetters(['my_default_collection']),
		use_llm_lesson_plans: {
			get() { return this.$store.state.lst.use_llm_lesson_plans },
			set(val) { this.$store.commit('lst_set', ['use_llm_lesson_plans', val]) }
		},
		enable_llm_lesson_plans() { 
			// if this.site_config.alba_collections is set, limit the enhanced lesson plan tool to these collections
			if (this.site_config.alba_collections) {
				if (!this.home_collection || !this.site_config.alba_collections.includes(this.home_collection.course_code)) return false
			}

			return this.site_config.enable_llm_lesson_plans == 'yes' 
		},
		use_enhanced_lesson_editor() { 
			return (this.enable_llm_lesson_plans && this.use_llm_lesson_plans)
		},
		citem_types() {
			// start with the item_types from props, but eliminate any that we're not allowing via supported_content_types
			let arr = []
			for (let t of this.item_types) {
				if (this.site_config.supported_content_types.includes(t)) arr.push(t)
			}
			return arr
		},
		cdialog_title() {
			let s = this.dialog_title
			// strip 'lessons' or 'activities' out of dialog title if we're not supporting them
			if (!this.site_config.supported_content_types.includes('lessons')) s = s.replace(/lessons/i, '')
			if (!this.site_config.supported_content_types.includes('activities')) s = s.replace(/activities/i, '')
			if (this.site_config.supported_content_types.length < 3) s = s.replace(/,/g, '')
			return s
		},
		resource_search_sort_by: {
			get() { return this.$store.state.lst.resource_search_sort_by },
			set(val) { this.$store.commit('lst_set', ['resource_search_sort_by', val]) }
		},
		resource_search_sort_by_created_at_order: {
			get() { return this.$store.state.lst.resource_search_sort_by_created_at_order },
			set(val) { this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', val]) }
		},
		total_pages() {
			return Math.ceil(this.search_results.length / this.items_per_page)
		},
		paginated_results() {
			const start = (this.current_page - 1) * this.items_per_page
			const end = start + this.items_per_page
			let paginated = this.search_results.slice(start, end)
			if (paginated.length === 0) {
				paginated = this.search_results.slice(start - this.items_per_page, end - this.items_per_page)
			}
			return paginated
		},
		new_or_search: {
			get() { return this.$store.state.lst.resource_selector_new_or_search },
			set(val) { this.$store.commit('lst_set', ['resource_selector_new_or_search', val]) }
		},
		selected_resource_search_types: {
			get() { return this.$store.state.lst.selected_resource_search_types },
			set(val) { this.$store.commit('lst_set', ['selected_resource_search_types', val]) }
		},
		search_lessons() { 
			if (this.citem_types.length == 1) return this.citem_types.includes('lessons')
			return this.selected_resource_search_types.includes(0) && this.citem_types.includes('lessons')
		},
		search_activities() { 
			if (this.citem_types.length == 1) return this.citem_types.includes('activities')
			return this.selected_resource_search_types.includes(1) && this.citem_types.includes('activities')
		},
		search_resources() { 
			if (this.citem_types.length == 1) return this.citem_types.includes('resources')
			return this.selected_resource_search_types.includes(2) && this.citem_types.includes('resources')
		},

		no_filters() { return !this.selected_home_lesson && !this.selected_home_collection && !this.selected_my && !this.selected_course && !this.selected_repository },
		no_filters_search_help() {
			if (empty(this.last_search_terms)) {
				if (!empty(this.search_terms)) return `Tap the enter key or click the <i class="fas fa-search"></i> search icon to load search results`
				else return `Enter search terms, and/or choose a different option from SEARCH IN…, to show search results`
			}

			// if we get to here, we have done a search
			// if no searchable items, that will be expressed in the line below no_filters_search_help
		},
		course_selection_array() {
			let arr = []
			for (let lp of this.all_courses) {
				// filter out resource repository collections, which start with 'C'
				// if (lp.course_code[0] == 'C') continue

				// skip inactive courses if user isn't an admin for the course
				if (lp.active != 'yes' && !(lp.user_can_view_lp && lp.user_can_view_lp())) continue

				// skip home_collection
				if (this.home_collection && this.home_collection.course_code == lp.course_code) continue

				arr.push({value:lp, text: lp.title})
			}
			let priority_collections = []
			let added_courses = new Set()
			// 1st priority is the last viewed collections
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.lst.last_collections_viewed.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 2nd priority is the user created
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.user_info.admin_rights.includes(`lp.course.${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 3rd priority is the added my courses
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.added_my_courses.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			const non_priority = arr.filter((e) => !priority_collections.includes(e))
			// sort alphabetically
			non_priority.sort((a,b)=>U.natural_sort(a.value.title, b.value.title))
			return [...priority_collections, ...non_priority]
		},
		repository_selection_array() {
			let arr = []
			for (let lp of this.all_courses) {
				// only include resource repository collections, which start with 'C'
				if (lp.course_code[0] != 'C') continue

				// skip inactive repos if user isn't an admin for the repo
				if (lp.active != 'yes' && !(lp.user_can_view_lp && lp.user_can_view_lp())) continue

				// skip home_collection
				if (this.home_collection && this.home_collection.course_code == lp.course_code) continue

				arr.push({value:lp, text: lp.title})
			}
			return arr
		},
		at_least_one_item_selected() {
			for (let item of this.search_results) {
				if (item.selected && !item.already_added) return true
			}
			return false
		},
		user_is_collection_editor() {
			if (!this.selected_course) return false
			if (this.selected_course.user_is_lp_admin()) return true
			return false
		},
		new_items_are_agency_sanctioned() {
			// if we're adding items to a non-agency-sanctioned collection, the items will never be agency_sanctioned
			if (!this.home_collection || !this.home_collection.agency_sanctioned) return false

			// and if we're adding items to a shadow unit, the items will never be agency_sanctioned
			if (!this.home_unit || this.home_unit.shadows_lp_unit_id) return false

			// if we get to here, items *should* be agency_sanctioned
			return true
		},

		// TEMP: whether or not to show the alba easter-egg button
		show_alba_btn() {
			// always show to pepper
			if (this.user_info.email.indexOf('pepper') == 0) return true
			if (this.home_collection && this.site_config.alba_collections.includes(this.home_collection.course_code)) return true
			return false
		},
		// TEMP: whether or not to show the gavs easter-egg button
		show_gavs_btn() {
			// show to commongoodlt emails, or the admin@henry.k12.ga.us email
			if (this.user_info.email.indexOf('commongoodlt') > -1) return true
			if (this.user_info.email == 'admin@henry.k12.ga.us') return true
			return false
		}
	},
	watch: {
		// These watchers take care of what we need to do when user chooses a different filter value
		no_filters() {
			if (this.no_filters == true) {
				this.$store.commit('lst_set', ['selected_resource_filter', 'none'])
			}
		},
		selected_home_lesson() {
			if (this.selected_home_lesson == true) {
				this.selected_home_collection = false
				this.selected_course = ''
				this.selected_my = false
				this.selected_repository = ''
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'home_lesson'])
			}

			this.limit_menu_showing = false
			this.generate_searchable_items()
		},
		selected_home_collection() {
			if (this.selected_home_collection == true) {
				this.selected_home_lesson = false
				this.selected_my = false
				this.selected_course = ''
				this.selected_repository = ''
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'home_collection'])
			}

			this.limit_menu_showing = false
			this.generate_searchable_items()
		},
		selected_my() {
			if (this.selected_my == true) {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_course = ''
				this.selected_repository = ''
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'my'])
			}

			this.limit_menu_showing = false
			this.generate_searchable_items()
		},
		selected_course() {
			if (this.selected_course) {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_repository = ''
				// load course if necessary
				if (empty(this.selected_course.lp_id) || !this.selected_course.fully_loaded) {
					this.$store.dispatch('get_learning_progression', this.selected_course.course_code).then((found)=>{
						this.selected_course = this.all_courses.find(x=>x.course_code == this.selected_course.course_code)
						this.generate_searchable_items()
					})
				}
				this.$store.commit('lst_set', ['selected_resource_filter', this.selected_course.course_code])
			}

			this.limit_menu_showing = false
			this.generate_searchable_items()
		},
		selected_repository() {
			if (this.selected_repository) {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_course = ''
				// load repository if necessary
				if (empty(this.selected_repository.lp_id) || !this.selected_repository.fully_loaded) {
					this.$store.dispatch('get_learning_progression', this.selected_repository.course_code).then((found)=>{
						this.selected_repository = this.all_courses.find(x=>x.course_code == this.selected_repository.course_code)
						this.generate_searchable_items()
					})
				}
				this.$store.commit('lst_set', ['selected_resource_filter', this.selected_repository.course_code])
			}

			this.limit_menu_showing = false
			this.generate_searchable_items()
		},
		search_lessons() { this.execute_search() },
		search_resources() { this.execute_search() },
		search_activities() { this.execute_search() },
		resource_search_sort_by() { this.apply_resource_search_sort(this.search_results) },
		resource_search_sort_by_created_at_order() { this.apply_resource_search_sort(this.search_results) },
	},
	created() {
		vapp.search_component = this

		if (!this.all_courses_loaded) this.$store.dispatch('get_all_courses').then(x=>this.restore_previously_selected_filter())
		else this.restore_previously_selected_filter()
	},
	mounted() {
		this.previous_resource_search_sort_by = this.resource_search_sort_by
		// get lesson_masters if we haven't already done so
		this.$store.dispatch('get_lesson_masters')
	},
	methods: {
		set_resource_search_sort_order() {
			if (this.previous_resource_search_sort_by == 'created_at') {
				if (this.resource_search_sort_by_created_at_order == 'desc') this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', 'asc'])
				else this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', 'desc'])
			}
			this.previous_resource_search_sort_by = 'created_at'
		},
		apply_resource_search_sort(arr) {
			// Group the input array by category(Lesson, Activity, Resource), then alphabetically or by created_at date within the category
			arr.sort((a, b) => {
				const category_comparison = U.natural_sort(a.category, b.category)
				if (category_comparison !== 0) return category_comparison

				// if sorting by created_at date...
				if (this.resource_search_sort_by == 'created_at') {
					if (this.resource_search_sort_by_created_at_order == 'asc') {
						return U.natural_sort(b.created_at + '', a.created_at + '')
					} else {
						return U.natural_sort(a.created_at + '', b.created_at + '')
					}
				}

				// default to alpha sort
				return U.natural_sort(a.text, b.text)
			})
			return arr
		},
		// restore the filter the user previously selected the last time they were adding content
		restore_previously_selected_filter() {
			if (this.$store.state.lst.selected_resource_filter == 'none') {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_course = ''
				this.selected_repository = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'home_lesson') {
				this.selected_home_lesson = (this.home_lesson != null)	// true if we were given a home lesson; false otherwise
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_course = ''
				this.selected_repository = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'home_collection' || this.$store.state.lst.selected_resource_filter === this.home_collection.course_code) {
				this.selected_home_lesson = false
				this.selected_home_collection = (this.home_collection != null)	// true if we were given a home course; false otherwise
				this.selected_my = false
				this.selected_course = ''
				this.selected_repository = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'my') {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = true
				this.selected_course = ''
				this.selected_repository = ''

			} else if (this.$store.state.lst.selected_resource_filter[0] == 'C') {
				// repository course_codes start with 'C'
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_course = this.all_courses.find(x=>x.course_code == this.$store.state.lst.selected_resource_filter) ?? ''
				this.selected_repository = ''

			} else {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_course = this.all_courses.find(x=>x.course_code == this.$store.state.lst.selected_resource_filter) ?? ''
				this.selected_repository = ''
			}

			// if we're not allowing the user to add other resources from the home collection/unit, make sure we don't start with these options selected (v-ifs above will make sure the options aren't allowed to select)
			if (!this.allow_add_from_home) {
				if (this.selected_my && this.home_collection == this.my_default_collection) {
					this.select_none()
				} else if (this.selected_home_lesson || this.selected_home_collection) {
					this.select_none()
				} else if (this.home_collection) {
					if (this.selected_course && this.selected_course.course_code == this.home_collection.course_code) this.select_none()
					else if (this.selected_repository && this.selected_repository.course_code == this.home_collection.course_code) this.select_none()
				}
			}
		},

		select_none() {
			// clear all the selected_xx values, so that we're searching everything in cureum
			this.selected_home_lesson = false
			this.selected_home_collection = false
			this.selected_my = false
			this.selected_course = ''
			this.selected_repository = ''
		},

		matches_search(s) {
			if (!U.string_includes_stop_word(this.stop_words, s)) {
				if (U.strings_match_search_term_res(this.search_term_res, [s])) {
					return true
				}
			}
			return false
		},

		search_field_keyup(evt) {
			// when enter key is tapped, call initiate_search_from_keyboard_or_search_icon
			this.db_query_offset = 0
			this.current_page = 1
			if (evt.keyCode == 13) this.initiate_search_from_keyboard_or_search_icon()
			else this.execute_search()
		},

		initiate_search_from_keyboard_or_search_icon() {
			// if we're *not* filtering by an lp or my resources, this is where we poll the server for potential search results
			if (this.no_filters) this.get_searchable_items_from_server()
		},

		execute_search() {
			let arr = U.create_search_re(this.search_terms)
			// console.log(arr, this.searchable_items, this.server_search_results)
			this.search_term_res = arr[0]
			this.stop_words = arr[1]
			let sr = []
			this.category_count = {'1lesson':[], '2sparkl':[], '3resource':[]}
			for (let item of this.searchable_items) {
				if (!this.search_lessons && item.category == '1lesson') continue
				if (!this.search_activities && item.category == '2sparkl') continue
				if (!this.search_resources && item.category == '3resource') continue

				if (empty(this.search_terms) || this.matches_search(item.text_lc)) {
					sr.push(item)
					this.category_count[item.category].push(item)
				}
			}
			// console.log(this.server_search_results.counts.resources, this.category_count['3resource'])
			this.search_results = sr
		},

		execute_search_clear() {
			this.search_terms = ''
			this.last_search_terms = ''
			this.search_results = []
			this.server_search_results = { resources:[], activities:[], lessons:[], counts: {resources:0, activities:0, lessons:0} }
			this.search_term_res = []
			this.stop_words = []
			this.current_page = 1
			this.db_query_offset = 0
			this.execute_search()
		},

		get_searchable_items_from_server() {
			this.search_terms = $.trim(this.search_terms)

			// require at least four characters to search
			if (this.search_terms.length < 4) {
				this.$alert('Please enter at least four characters in your search string.')
				return
			}

			// if we previously searched these same terms, don't re-search
			if (this.search_terms == this.last_search_terms && this.db_query_offset === 0) return

			this.last_search_terms = this.search_terms

			let payload = {
				user_id: vapp.user_info.user_id,
				return_abbrev: false,
				stem: this.search_terms,
				offset: this.db_query_offset
			}
			payload.get_resources = (this.search_resources) ? 'yes' : 'no'
			payload.get_lessons = (this.search_lessons) ? 'yes' : 'no'
			payload.get_activities = (this.search_activities) ? 'yes' : 'no'
			U.loading_start()
			U.ajax('resource_get_searchable_items', payload, result=>{
				U.loading_stop()
				console.log(result)

				// store in server_search_results
				// let o = { resources:[], activities:[], lessons:[], counts:result.counts }
				for (let r of result.resources) this.server_search_results.resources.push(new Resource(r))
				for (let r of result.lessons) this.server_search_results.lessons.push(new Lesson(r))
				for (let r of result.activities) this.server_search_results.activities.push(new Resource(r))

				// Server search results is what is retrieved from the server
				this.server_search_results.counts = result.counts
				this.generate_searchable_items()
			})
		},

		generate_searchable_items() {
			// establish the debounce fn if necessary
			if (empty(this.generate_searchable_items_debounced)) {
				this.generate_searchable_items_debounced = U.debounce(function(x) {

					// this fn is for traversing a resource collection generated from a common cartridge (i.e. HMH resources in HenryConnects)
					function traverse(resource_arr, collection_id, node, collection_inclusions, parent_included) {
						// we assume here that each node is *either* a resource (where the resource_id is specified by 'r') or a "folder" (with children in 'c')
						if (!empty(node.r)) {
							// if this resource's parent is included, include the resource
							if (parent_included) resource_arr.push(new Resource({
								resource_id: node.r,
								type: 'collection_item',
								teacher_facing: (node.i == 1),	//
								description: node.t,
							}))

						} else if (!empty(node.c)) {
							// this is a folder; if it is either implicitly or explicitly included, its child resources should be included
							let node_included_explicitly = empty(collection_inclusions) || !empty(collection_inclusions.find(x=>x==node.f))	// node.f = identifier, from CC file
							let node_included_implicitly = !node_included_explicitly && parent_included

							for (let child of node.c) {
								// traverse child; parent_included is true if nn is either explicitly or implicitly included
								traverse(resource_arr, collection_id, child, collection_inclusions, (node_included_explicitly || node_included_implicitly))
							}
						}
					}

					// fn for adding an item to the the return array
					let add_item = (item, type, text, lp_unit_id = 0) => {
						let o = {}
						if (type == 'resource') {
							// only add each item once
							if (arr.find(x=>x.value.resource_id == item.resource_id)) return
							// also skip duplicate urls for resources
							if (arr.find(x=>x.value.url == item.url)) return
							// mark items that already exist
							if (this.existing_resources.find(x=>x.resource_id == item.resource_id)) o.already_added = true
							// add item_id
							o.item_id = item.resource_id

							// sparkl resources go in the sparkl category
							if (item.type == 'sparkl') o.category = '2sparkl'
							else o.category = '3resource'
						}
						if (type == 'lesson') {
							if (arr.find(x=>x.value.lesson_id == item.lesson_id)) return
							if (this.existing_lessons.find(x=>x.lesson_id == item.lesson_id)) o.already_added = true
							o.item_id = item.lesson_id
							o.category = '1lesson'
						}
						if (type == 'activity') {
							if (arr.find(x=>x.value.resource_id == item.resource_id)) return
							if (this.existing_activities.find(x=>x.resource_id == item.resource_id)) o.already_added = true
							o.item_id = item.resource_id
							o.category = '2sparkl'
						}
						o.value = item
						o.type = type
						o.text = text
						o.created_at = item.created_at
						o.lp_unit_id = lp_unit_id
						o.text_lc = o.text.toLowerCase()
						o.selected = o.already_added
						arr.push(o)

						// add to category_count as we go
						// this.category_count[o.category] += 1
					}

					// this.category_count = {'1lesson':0, '2sparkl':0, '3resource':0}
					let arr = []
					if (this.selected_course || this.selected_repository || this.selected_home_collection || this.selected_my) {
						let lp
						if (this.selected_course) lp = this.selected_course
						else if (this.selected_repository) lp = this.selected_repository
						else if (this.selected_home_collection) lp = this.home_collection
						// if selected_my, use the users default collection
						else if (this.selected_my) lp = this.my_default_collection

						for (let unit of lp.units) {
							let unit_title = unit.title ? `<span class="k-full-resource-search-result-unit">${unit.title}</span>` : ''
							let lp_unit_id = unit.lp_unit_id
							for (let r of unit.resources) {
								if (r.placeholder == true) continue		// don't add the placeholder item
								if (this.selected_my) add_item(r, 'resource', sr('$2', unit_title, r.description, r.type_label()))	//  ($3)
								else add_item(r, 'resource', sr('$1$2', unit_title, r.description, r.type_label()), lp_unit_id)	//  ($3)
							}

							// add lessons, and resources in lessons
							for (let l of unit.lessons) {
								if (this.selected_my) add_item(l, 'lesson', sr('$2', unit_title, l.lesson_title))
								else add_item(l, 'lesson', sr('$1$2', unit_title, l.lesson_title, lp_unit_id), lp_unit_id)

								// TODO: do we need to load the full lessons in order to get the list of resources??
								for (let r of l.resources) {
									if (this.selected_my) add_item(r, 'resource', sr('$2 (from Lesson)', unit_title, r.description, r.type_label()))	// $3 -
									else add_item(r, 'resource', sr('$1$2<span class="k-full-resource-search-result-from-lesson">(from Lesson)</span>', unit_title, r.description, r.type_label()), lp_unit_id)	// $3 -
								}
							}
						}
						
						// add course_wide_resources
						for (let r of lp.course_wide_resources) {
							add_item(r, 'resource', sr('Course-Wide: $1', r.description, r.type_label()))	//  ($2)
						}

						// add common cartridge items if there
						for (let rc of lp.resource_collections) {
							let col_arr = []
							traverse(col_arr, rc.resource_id, rc.collection_json, null, true)
							for (let r of col_arr) {
								add_item(r, 'resource', sr('TEXTBOOK: $1', r.description))
							}
						}

						// TODO: if this is an agency-sanctioned repo, add items from the user's "shadow units"

					} else if (this.selected_home_lesson) {
						for (let r of this.home_lesson.resources) {
							add_item(r, 'resource', sr('$1', r.description, r.type_label()))	//  ($2)
						}

					} else {
						// results of server-side search
						for (let r of this.server_search_results.resources) {
							add_item(r, 'resource', sr('$1', r.description, r.type_label()))	//  ($2)
						}

						for (let a of this.server_search_results.activities) {
							add_item(a, 'activity', a.description)
						}

						for (let l of this.server_search_results.lessons) {
							add_item(l, 'lesson', l.lesson_title)

							// resources from the lesson
							for (let r of l.resources) {
								add_item(r, 'resource', sr('$1 (from Lesson)', r.description, r.type_label()))	// $2 – 
							}
						}
					}

					this.searchable_items = this.apply_resource_search_sort(arr)
					this.execute_search()
				}, 10)
			}
			// console.log(this.searchable_items)
			// call the debounce fn
			this.generate_searchable_items_debounced()
		},
		show_more() {
			this.db_query_offset += 50
			this.get_searchable_items_from_server()
		},

		result_class(sr, hover) {
			let s = ''
			if (sr.already_added) s += ' k-full-resource-search-result-already-added'
			if (sr == this.last_previewed_item) s += ' indigo lighten-5'
			else if (hover) s += ' k-full-resource-search-result-hovered'
			return s
		},

		created_date(sr) {
			let d 
			if (sr.category === "1lesson") {
				d = new Date(sr.value.created_at*1000)
			} else {
				d = date.parse(sr.value.created_at, 'YYYY-MM-DD HH:mm:ss')
			}
			return date.format(d, 'MMM D, YYYY')	// Jan 3, 2020 3:04 PM
		},

		item_checked(sr) {
			// if max_items is 1, only allow one item to be checked; so uncheck any item except sr
			if (this.max_items == 1) {
				for (let i = 0; i < this.search_results.length; ++i) {
					if (this.search_results[i] != sr) {
						this.search_results[i].selected = false
					}
				}
			}
		},

		add_selected_items() {
			if (!this.at_least_one_item_selected) return

			// add items that are selected and aren't already in the collection we're adding to
			let arr = this.search_results.filter(x=>x.selected && !x.already_added)

			// extract list of lessons and sparkls we're adding
			let arr2 = arr.filter(x=>x.category == '1lesson' || x.category == '2sparkl')

			// if we're not adding resources *to* a lesson,
			// AND we're adding lessons or activities from selected_my (items from the user's default collection)
			// OR if we are moving from a collection that the user is an editor of,
			// ask the user if they want to COPY or MOVE
			// SF: Simplified this logic to remove the this.selected_home_collection, as this is trumped by this.user_is_collection_editor and it was causing an issue when adding directly to "My Content" from the active collection
			const user_can_move_resource = this.user_is_collection_editor || this.selected_my

			if (!this.adding_to_lesson && (user_can_move_resource)) {
				let noun = (arr.length == 1) ? 'item' : `items`
				let noun2 = (arr.length == 1) ? 'an ' + noun : noun
				let noun3 = (arr.length == 1) ? 'it' : 'them'
				let origin_collection = this.selected_my ? 'your Default Collection' : `“${this.selected_course.title}”`
				let origin_collection_abbrev = this.selected_my ? 'your Default Collection' : 'the original collection'
				let destination_noun = 'collection'
				if (this.selected_home_collection) {
					origin_collection = `another unit in “${this.home_collection.title}”`
					origin_collection_abbrev = 'the other unit'
					destination_noun = 'unit'
				}
				this.$confirm({
					title: 'Copy or Move?',
					text: `You’ve selected ${noun2} from ${origin_collection}. Would you like to:<ul><li class="mt-1"><b>COPY</b> the selected ${noun} from ${origin_collection_abbrev} to the current ${destination_noun}, or</li><li class="mt-1"><b>MOVE</b> the selected ${noun} from ${origin_collection_abbrev} to the current ${destination_noun} (removing ${noun3} from ${origin_collection_abbrev})?</li></ul>`,
					extraBtnText: '  Copy',
					extraBtnIconAfter: 'fas fa-copy',
					acceptText: '  Move',
					acceptIconAfter: 'fas fa-truck-moving',
					cancelIcon: 'fas fa-times',
					dialogMaxWidth: 640,
				}).then(result => {
					// if they click the 'extra' btn, we will get result === 'extra', which means COPY
					if (result === 'extra') {
						// if we have any lessons/sparkls, we have to copy them before adding
						if (arr2.length > 0) {
							this.copy_items_before_adding(arr, arr2)
						
						// else we want to leave all the non-sparkl resources in my and add them to the new collection, so proceed
						} else {
							this.add_selected_items_finish(arr)
						}

					// else user clicked the accept button, meaning we're MOVING
					} else {
						// here we have to remove the items from my before adding them to the new collection
						this.move_items_before_adding(arr)
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
			
			// else we're adding from somewhere else, so we ALWAYS COPY
			} else {
				// if we have any lessons/sparkls, we have to copy them before adding
				if (arr2.length > 0) {
					this.copy_items_before_adding(arr, arr2)
				
				// else we want to leave all the non-sparkl resources where they are and add them to the new collection, so proceed
				} else {
					this.add_selected_items_finish(arr)
				}
			}
		},

		move_items_before_adding(arr) {
			// We allow moving from your default collection AND from any collection user is the owner of, so we just have to first remove the items in arr whichever of those is the origin here
			let payload = {
				user_id: this.user_info.user_id,
				resource_ids: [],
				lesson_ids: [],
			}
			if (this.selected_home_collection) this.selected_course = this.home_collection

			// move_items_before_adding will set agency_sanctioned for the to-be-moved assets if we tell it to here
			if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

			for (let sr of arr) {
				let lp_id = ''
				let user_id = 0
				// If we are moving from a collection other than the default collection, we need to pass the collection ID in to the service
				if (!this.selected_my) lp_id = this.selected_course.lp_id
				else {
					// even if the item is in my sandbox, it might also be tied to a different LP; if so we want to move it from there
					let cam = this.$store.state.my_ca_mappings.find(x=>x.asset_id==sr.item_id)
					if (cam) {
						lp_id = cam.collection_id
						user_id = cam.user_id		// in this case the user_id might be 0 or the user's id
					}
				}

				// for each item, add [item_id, lp_id, user_id] (where lp_id might be empty, and user_id might be 0); the service will use these to decide what to remove
				if (sr.type == 'lesson') payload.lesson_ids.push([sr.item_id, lp_id, user_id])
				else payload.resource_ids.push([sr.item_id, lp_id, user_id])
			}

			U.loading_start()
			U.ajax('remove_assets_from_collection', payload, result => {
				U.loading_stop()
				for (let sr of arr) {
					// If we are moving from the default collection...
					if (this.selected_my) {
						// remove the items from the user's default collection -- lessons are in unit 0, activities in unit 1, and other resources in unit 2
						if (sr.type == 'lesson') {
							let i = this.my_default_collection.units[0].lessons.findIndex(x => x.lesson_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[0].lessons, 'SPLICE', i])
						} else if (sr.type == 'activity') {
							let i = this.my_default_collection.units[1].resources.findIndex(x => x.resource_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[1].resources, 'SPLICE', i])
						} else {
							let i = this.my_default_collection.units[2].resources.findIndex(x => x.resource_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[2].resources, 'SPLICE', i])
						}
					} else {
						// Otherwise, we are moving from a collection 'owned' by the current user
						// Here we need to figure out which unit the item was coming from and remove it...
						const unit_index = this.selected_course.units.findIndex(x => x.lp_unit_id == sr.lp_unit_id)
						if (unit_index > -1) {
							if (sr.type == 'lesson') {
								let i = this.selected_course.units[unit_index].lessons.findIndex(x => x.lesson_id == sr.item_id)
								if (i > -1) this.$store.commit('set', [this.selected_course.units[unit_index].lessons, 'SPLICE', i])
							} else {
								let i = this.selected_course.units[unit_index].resources.findIndex(x => x.resource_id == sr.item_id)
								if (i > -1) this.$store.commit('set', [this.selected_course.units[unit_index].resources, 'SPLICE', i])
							}
						}
					}
				}
				// If we didn't move from default collection...
				if (!this.selected_my) {
					// ...update the origin LP in database This will remove the moved lessons and resources from the lp json 
					this.$store.dispatch('save_learning_progression', this.selected_course).then(() => {
						this.add_selected_items_finish(arr)
					})

				// Otherwise, we did move from the default collection...
				} else {
					this.add_selected_items_finish(arr)
				}
			})
		},

		copy_items_before_adding(arr, arr2) {
			// make copies of assets in arr2, then add the copies
			let payload = {
				user_id: this.user_info.user_id, 
				resource_ids: [], 
				lesson_ids: [],
				agency_sanctioned: 'no',
				// when we copy an activity this way -- when adding to a collection -- we *always* copy the activity's exercises, even if it's an agency-sanctioned activity
				copy_exercises: 'yes',
			}

			// if we don't have a home_collection for some reason, assume the copy shouldn't be agency_sanctioned
			if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

			for (let sr of arr2) {
				if (sr.type == 'lesson') payload.lesson_ids.push(sr.item_id)
				else payload.resource_ids.push(sr.item_id)
			}
			U.loading_start()
			U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
				U.loading_stop()

				if (result.status != 'ok') {
					this.$alert(result.status)
					return
				}

				// replace the asset id's in arr with the newly-created assets
				for (let o of result.copies) {
					// note that arr contains references to the objects in sr 
					let sr = this.search_results.find(x=>x.item_id == o.original_item_id)	// don't worry about searching on type, because lesson item_ids are integers and resource/activity item_ids are guids
					if (sr) {	// this should always evaluate to true
						// switch to the copied item_id and copied lesson/resource record
						sr.item_id = o.copied_item_id
						if (sr.type == 'lesson') sr.value = new Lesson(o.lesson_data)
						else sr.value = new Resource(o.resource_data)
					}
				}

				// now continue adding; we will add the copies
				this.add_selected_items_finish(arr)
			})

			// TODO: account for indicating, in search results, items where we have already added copies of the item into the current collection
		},

		add_selected_items_finish(arr) {
			// if the collection we're adding to *is* agency_sanctioned, and any items are *not* agency_sanctioned, we have to mark those items as agency_sanctioned
			if (this.new_items_are_agency_sanctioned) {
				let payload = { user_id: this.user_info.user_id, assets: [] }
				for (let sr of arr) {
					if (!sr.value.agency_sanctioned) {
						payload.assets.push({type: sr.type, id: sr.item_id})
						// mark the asset as agency_sanctioned here, so we don't have to wait for mark_assets_as_agency_sanctioned to return (not sure if sr.value is in store, but set just in case)
						this.$store.commit('set', [sr.value, 'agency_sanctioned', true])
					}
				}
				if (payload.assets.length > 0) {
					U.ajax('mark_assets_as_agency_sanctioned', payload, result=>{
						if (result.status != 'ok') {
							this.$alert('mark_assets_as_agency_sanctioned: ' + result.status)
							return
						}
					})
				}
			}

			this.$emit('add_items_from_search', arr)

			// mark these items as already_added, using set for quick lookup
			const arr_item_ids = new Set(arr.map((item) => item.item_id))
			for (let i = 0; i < this.search_results.length; ++i) {
				if (arr_item_ids.has(this.search_results[i].item_id)) {
					let sr = Object.assign({}, this.search_results[i])
					sr.already_added = true
					this.search_results.splice(i, 1, sr)
				}
			}
		},

		preview_item(sr) {
			// set last_previewed_item so that we highlight the item
			this.last_previewed_item = sr

			if (sr.category == '2sparkl') {
				this.show_sparkl(sr.value, 'view')
				return

			} else if (sr.category == '1lesson') {
				this.previewed_lesson = sr.value
				return
			}

			let url
			// if (sr.type == 'lesson') url = sr.value.standalone_link()
			if (sr.value.type == 'upload' || sr.value.type == 'html') url = sr.value.full_url()
			else url = sr.value.url
			window.open(url, 'search_preview')
		},

		show_sparkl(sparkl_item_to_open, embed_mode) {
			// originally borrowed from ResourceCollectionItem.vue
			// This structure holds the info used in SparklEmbed, which we extract here from the resource object
			let activity_record = {
				tool_activity_id: sparkl_item_to_open.url,
				lti_resource_link_id: sparkl_item_to_open.resource_id,
				activity_title: sparkl_item_to_open.description,
				creator_user_id: sparkl_item_to_open.creator,
			}

			// override sparkl_origin if the collection tells us to do so (used for AP Sparkl activities, e.g.)
			let sparkl_origin_override = null
			if (this.home_collection && !empty(this.home_collection.sparkl_origin_override)) {
				sparkl_origin_override = this.home_collection.sparkl_origin_override
			}

			this.open_sparkl_activity_embed_object = {
				resource: sparkl_item_to_open,
				activity_record: activity_record,
				embed_mode: embed_mode,	// 'view' or 'edit'
				in_unit_editor: true,	// TODO: this might be false
				in_my_default_collection: false,	// TODO: this might be true
				// viewing_original_of_in_my_collections: false,
				controller_component: this,
				sparkl_origin_override: sparkl_origin_override,
			}
			vapp.$refs.sparkl_embed.show_activity(this.open_sparkl_activity_embed_object)

			// hide active dialogs/overlays while the activity shows (e.g. the unit editor might be showing)
			$('.v-dialog__content--active, .v-overlay--active').hide()
		},

		// this is called by the SparklEmbed component when the user clicks the "CLOSE" btn in Cureum (controller_component.close_sparkl())
		close_sparkl() {
			// if the activity is open for editing, we need to ask Sparkl if anything needs to be saved
			if (this.open_sparkl_activity_embed_object.embed_mode == 'edit') {
				U.loading_start()	// we'll close the spinner in close_sparkl_finish
				// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
				vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

				this.sparkl_closed_from_embed = true
				// ... then once sparkl is done saving, sparkl_activity_saved (below) will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish

			} else {
				// not open for editing, so close immediately
				this.close_sparkl_finish()
			}
		},

		close_sparkl_finish() {
			U.loading_stop()
			// reset sparkl_closed_from_embed
			this.sparkl_closed_from_embed = false

			// clear open_sparkl_activity_embed_object
			this.open_sparkl_activity_embed_object = null

			// clear new_activity and new_activity_saved if set
			this.new_activity = null
			this.new_activity_saved = false

			// call sparkl_embed.hide_activity
			vapp.$refs.sparkl_embed.hide_activity()

			// re-show active dialogs/overlays
			$('.v-dialog__content--active, .v-overlay--active').show()
		},

		// TODO: fill this in...
		copy_to_my_content() {
			this.$alert('You are currently previewing this activity.')
		},

		///////////////////////////////////////// Import
		import_shared_item(item_type) {
			let params = {acceptText:'Import'}
			if (item_type == 'lesson') {
				params.title = 'Import Shared Lesson'
				params.text = 'Enter the shared Lesson ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L”.</div>'
			} else if (item_type == 'activity') {
				params.title = 'Import Shared Activity'
				params.text = 'Enter the shared Activity ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “A”.</div>'
			} else {
				params.title = 'Import Shared Resource'
				params.text = 'Enter the shared Resource ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with “R-”.</div>'
			}

			this.$prompt(params).then(entered_item_id => {
				if (entered_item_id.search(/^([ALR])(-?)([\w-]+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$3

				// for a non-sparkl resource, you can't add the same resource two times in the same unit, so reject if this is the case
				// note that if adding_to_lesson is true, we're importing to a *lesson*, and there you can do this (?)
				if (item_type == 'resource' && !this.adding_to_lesson && this.home_unit) {
					if (this.home_unit.resources.find(x=>x.resource_id==item_id)) {
						this.$alert('The specified resource already exists in this unit. You cannot import the same resource twice in the same unit.')
						return
					}
				}
				// for lessons and sparkls, this will create a copy, which is a feature

				// use copy_assets_for_adding_to_collection to copy the specified item
				let payload = {
					user_id: this.user_info.user_id, 
					resource_ids: [], 
					lesson_ids: [],
					agency_sanctioned: 'no',
				}

				// if we're using a sparkl_origin_override for this collection, send it in
				if (this.home_collection && !empty(this.home_collection.sparkl_origin_override)) {
					payload.sparkl_origin_override = this.home_collection.sparkl_origin_override
				}

				// if we don't have a home_collection for some reason, assume the copy shouldn't be agency_sanctioned
				if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

				if (item_id_prefix == 'L') payload.lesson_ids.push(item_id)
				else payload.resource_ids.push(item_id)

				U.loading_start()
				U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						// the service could fail because the user enters an invalid item ID
						if (result.status != 'bad_item_id') result.status = `The Item ID you entered (${item_id_prefix}${item_id}) was invalid.`
						this.$alert(result.status)
						return
					}

					// create a search result object for the copied item
					let sr
					if (item_id_prefix == 'L') {
						let l = new Lesson(result.copies[0].lesson_data)
						sr = {
							type: 'lesson',
							item_id: l.lesson_id,
							value: l,
							text: l.lesson_title,
							already_added: true,
							selected: true,
						}
					} else {
						let r = new Resource(result.copies[0].resource_data)
						sr = {
							type: 'resource',
							item_id: r.resource_id,
							value: r,
							text: r.description,
							already_added: true,
							selected: true,
						}
					}

					// add to imported_items
					this.imported_items.push(sr)

					// add the item like we do with search -- the handler of add_items_from_search will add the item to whatever collection we're saving
					this.$emit('add_items_from_search', [sr])
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		///////////////////////////////////////// Create
		create_new_resource() {
			console.log('here', this.home_collection)
			this.new_resource_placeholder = new Resource({
				resource_id: 'new',
				agency_sanctioned: this.new_items_are_agency_sanctioned,
				description: '', 
				teacher_facing: this.$store.state.lst.resource_editor_teacher_facing,
			})
		},

		create_resource_saved(resource_data) {
			// add created resource like we do with search
			let r = new Resource(resource_data)
			let sr = {
				type: 'resource',
				item_id: r.resource_id,
				value: r,
				text: r.description,
				already_added: true,
				selected: true,
			}
			this.created_items.push(sr)
			// the handler of add_items_from_search will add the resource to whatever collection we're saving
			this.$emit('add_items_from_search', [sr])
			this.create_resource_cancel()
		},

		create_resource_cancel() {
			this.new_resource_placeholder = null
		},

		create_lesson_start() {
			// create new_lesson
			this.new_lesson = new Lesson({
				lesson_title: 'New Lesson', 
				creator_user_id: this.user_info.user_id,
				agency_sanctioned: this.new_items_are_agency_sanctioned,
				course_code: this.home_collection ? this.home_collection.course_code : '',
				lp_unit_id: this.home_unit ? this.home_unit.lp_unit_id : 0,
			})

			// set new_lesson_class; by default it's 'teacher'
			this.new_lesson_class = 'teacher'
			// TODO: determine if this should be a template lesson
			// if (this.item_to_show.is_lesson_template) this.new_lesson_class = 'template'

			console.log('create_lesson_start', this.new_lesson)
		},

		create_lesson_cancel() {
			this.new_lesson = null
		},

		create_lesson_saved(args) {
			// the lesson editor will have saved the resource to the db
			let l = new Lesson(args.updated_lesson)
			let sr = {
				type: 'lesson',
				item_id: l.lesson_id,
				value: l,
				text: l.lesson_title,
				already_added: true,
				selected: true,
			}

			// apply *_showing values from edited_lesson
			if (args.edited_lesson) {	// this should always be true
				l.resources_showing = args.edited_lesson.resources_showing
				l.standards_showing = args.edited_lesson.standards_showing
				l.student_description_showing = args.edited_lesson.student_description_showing
				for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
					l.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
				}
			}

			// if we just saved the newly-created lesson for the first time...
			if (this.new_lesson.lesson_id == 0) {
				// add to created_items
				this.created_items.push(sr)
				// add the lesson like we do with search -- the handler of add_items_from_search will add the lesson to whatever collection we're saving
				this.$emit('add_items_from_search', [sr])

				// set new_lesson to l, and allow the user to continue to edit it (unless the flag says to close)
				if (args.flag != 'and_close') {
					this.new_lesson = null
					this.$nextTick(x=>this.new_lesson = l)
					console.log('create_lesson_saved!:', l)
				}

			} else {
				// else the user saved once and continued editing, so use edit_item_saved to save it; also update it in created_items
				this.$emit('edit_item_saved', {type:'lesson', updated_lesson: l})
				let index = this.created_items.findIndex(x=>x.item_id == l.lesson_id)
				if (index != -1) this.created_items.splice(index, 1, sr)	// we should always find this
			}

			// if the flag is 'and_close', close the editor
			if (args.flag == 'and_close') this.create_lesson_cancel()
		},

		create_activity_start() {
			console.log('create_activity_start')
			this.$prompt({
				title: `Create New ${this.site_config.sparkl_app_name} Student Activity`,
				text: 'Enter new activity title:',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Create Activity',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				// create a stub of the new activity to be created
				this.new_activity = new Resource({
					resource_id: 'new',	// triggers a new guid
					agency_sanctioned: this.new_items_are_agency_sanctioned,
					type: 'sparkl',
					url: '0',	// sparkl activity_id; this triggers sparkl to create a new sparkl activity
					description: title,
					creator: this.user_info.user_id,
				})
				this.new_activity_saved = false
				console.log('create_activity_start', this.new_activity)
				
				// open sparkl_embed to show the activity
				this.show_sparkl(this.new_activity, 'edit')
				// as user saves/closes, sparkl_activity_saved will be called

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			console.log('sparkl_activity_saved in ResourceSearch', activity_data_from_sparkl)
			// this is called when Sparkl issues a 'sparkl_activity_saved' message
			// activity_data_from_sparkl should include sparkl_activity_id, stars_available, activity_instructions, activity_title, activity_editors
			// note that for a newly-created activity, activity_id and tool_activity_id will both initially be 0

			// NOTE: removing code from ResourceCollectionItem that is executed when sparkl_activity_owned_by_user is false... we might need to bring this back; it is used either when "finishing the process of copying the activity to my content", or when editing a resource that is actually owned by the teacher, mostly in the unit editor context

			// NOTE: Code below needs to be kept in synch with ResourceCollectionItem (it's not identical, but some things are the same)
			let changed = false
			let resource_copy = this.open_sparkl_activity_embed_object.resource.copy_for_save()

			// if sparkl_activity_id is changed, set it
			if (activity_data_from_sparkl.sparkl_activity_id && activity_data_from_sparkl.sparkl_activity_id != resource_copy.url) {
				resource_copy.url = activity_data_from_sparkl.sparkl_activity_id
				changed = true
			}
			
			// ditto the activity title
			if (activity_data_from_sparkl.activity_title && activity_data_from_sparkl.activity_title != resource_copy.description) {
				resource_copy.description = activity_data_from_sparkl.activity_title
				changed = true
			}

			// build activity description from the rest of the activity_data_from_sparkl; if changed, set it in resource.long_description
			// (we're not currently using this description, but we might in the future)
			if (activity_data_from_sparkl.stars_available) {
				let d = `Student activity with ${activity_data_from_sparkl.stars_available} star${activity_data_from_sparkl.stars_available == 1 ? '' : 's'} available to be earned`
				
				if (resource_copy.long_description != d) {
					resource_copy.long_description = d
					changed = true
				}
			}

			// if we changed anything, save the activity resource to the db (if it was a new activity, the sparkl_activity_id/url will have changed from 0 to the newly-created sparkl activity_id, so changed will have been set to true above)
			if (changed) {
				console.log('saving sparkl activity (ResourceSearch)...')
				U.loading_start()
				this.$store.dispatch('save_resource', { resource: new Resource(resource_copy) }).then(saved_resource_data => {
					U.loading_stop()

					let r = new Resource(saved_resource_data)
					let sr = {
						type: 'resource',
						item_id: r.resource_id,
						value: r,
						text: r.description,
						already_added: true,
						selected: true,
					}

					// if we're showing a newly-created activity...
					if (this.new_activity) {
						// follow pattern from create_lesson_saved: if we just saved a newly-created activity for the first time...
						if (!this.new_activity_saved) {
							// add to created_items
							this.created_items.push(sr)
							// add the activity like we do with search -- the handler of add_items_from_search will add the activity to whatever collection we're saving
							this.$emit('add_items_from_search', [sr])
							this.new_activity_saved = true

						} else {
							// else the user saved once and continued editing, so use edit_item_saved to save it; also update it in created_items
							this.$emit('edit_item_saved', {type:'resource', updated_resource: r})
							let index = this.created_items.findIndex(x=>x.item_id == r.resource_id)
							if (index != -1) this.created_items.splice(index, 1, sr)	// we should always find this
						}

						// set this.new_activity to the new version of the resource
						this.new_activity = r
					
					// else user is previewing an activity they are an editor for, and has made a change (we can't stop them from doing this)
					} else {
						// emit edit_item_saved to update the activity in whatever collection we're editing
						this.$emit('edit_item_saved', {type:'resource', updated_resource: r})
					}

					// set open_sparkl_activity_embed_object.activity_record to the new activity resource data
					this.open_sparkl_activity_embed_object.activity_record = {
						tool_activity_id: r.url,
						lti_resource_link_id: r.resource_id,
						activity_title: r.description,
						creator_user_id: r.creator,
					}

					// once activity is saved, if sparkl_closed_from_embed is true, finish closing sparkl
					if (this.sparkl_closed_from_embed) this.$nextTick(()=>this.close_sparkl_finish())
				})
				// return here so we don't call close_sparkl_finish too quickly below 
				return
			}

			// if we didn't have to save, and sparkl_closed_from_embed is true, finish closing sparkl here
			if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
		},

		///////////////////////////////////////
		done_clicked() {
			if (this.at_least_one_item_selected) {
				this.$confirm({
					title: 'Add Item(s) Before Closing?',
					text: `You have selected at least one item to add. Would you like to add this/these items befor closing the search interface?`,
					cancelText: 'Add items, then close',
					acceptText: 'Close without adding',
					dialogMaxWidth: 600,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.$emit('dialog_cancel')
				}).catch(n=>{
					// note that the "add" option is in the cancel position
					this.add_selected_items()
					this.$emit('dialog_cancel')
				}).finally(f=>{})
			} else {
				this.$emit('dialog_cancel')
			}
		},

		use_llm_clicked() {
			if (this.use_llm_lesson_plans) vapp.show_llm_lesson_plan_description()
		},
	}
}
</script>

<style lang="scss">
.k-full-resource-search-result-category {
	background-color:$v-indigo-lighten-4;
	padding:4px 8px;
	border-radius:3px;
	margin-top:20px;
}

.k-full-resource-search-result {
	display:flex;
	// margin:2px 0 6px 0;
	padding:4px 0 0px 4px;
	font-size:14px;
	border-radius:4px;
}

.k-full-resource-search-result-unit {
	color:#555;
	font-size:0.85em;
	margin-right:8px;
}

.k-full-resource-search-result-from-lesson {
	color:#555;
	font-size:0.85em;
	margin-left:8px;
}

.k-full-resource-search-result-already-added {
	opacity:0.7;
}
.k-full-resource-search-result-hovered { background-color:#eee!important; }
.k-full-resource-search-sort-and-pagination-container {
	position: relative;

	&__pagination {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
