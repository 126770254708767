<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="95vw" persistent scrollable overlay-opacity="0.9"><div style="position:relative" class="">
		<v-btn class="k-lesson-print-close-btn" style="margin-right:10px;margin-top:10px;" icon color="#666" @click.stop="$emit('dialog_cancel')"><v-icon large>fas fa-times-circle</v-icon></v-btn>
		<v-card>
			<v-card-text class="pt-4" style="font-size:16px; background-color:#eee;">
				<div class="d-flex">
					<div class="k-print-settings elevation-3">
						<h3 class="mt-3 mb-2"><b>Print Settings</b></h3>
						<v-checkbox class="mt-0 pt-0" :label="'Print QR codes for resource links'" v-model="settings.qr_codes" hide-details></v-checkbox>
						<v-checkbox v-if="site_config.include_pdf_for_lesson_print=='yes'" class="mt-0 pt-0" :label="'Include PDF resources'" v-model="include_resources" hide-details></v-checkbox>
					</div>

					<div class="k-print-preview elevation-3">
						<div class="mb-3 mr-3 d-flex align-center">
							<h3><b>Print Preview</b></h3>
							<v-spacer/>
							<v-btn :disabled="!changes_pending" small color="primary" class="ml-3" @click="write_print_view"><v-icon small class="mr-2">fas fa-arrows-rotate</v-icon> Refresh Preview</v-btn>
							<v-btn :disabled="changes_pending" small color="primary" class="ml-3" @click="print"><v-icon small class="mr-2">fas fa-print</v-icon> Print</v-btn>
							<v-btn :disabled="changes_pending" small color="primary" class="ml-3" @click="open_in_new_window"><v-icon small class="mr-2">fas fa-external-link</v-icon> Open In New Window</v-btn>
						</div>
						<iframe v-show="!include_resources" name="print_view_no_resources" class="k-print-view-iframe"></iframe>
						<iframe v-if="include_resources&&pdf_url" name="print_view_with_resources" class="k-print-view-iframe" :src="pdf_url"></iframe>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</div></v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import kjua from 'kjua'

var pi_styles = `
<style>
body {
	font-family:Roboto, sans-serif;
	font-size:14px;
	line-height:1.4em;
	color:#000;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	color-adjust: exact;
}

.k-print-view-lesson-title {
	font-weight:bold;
	font-size:20px;
	margin:18px 4px;
}


.nobr {
	white-space:nowrap;
}

@page {
	margin:0.5in;
}

/*
@page{ size:auto; margin:5mm; }

.footer {
	position: fixed;
	bottom: 4px;
	left: 8px;
	width:calc(100vw - 16px);
}

.header {
	position: fixed;
	top: 4px;
	left: 8px;
	width:calc(100vw - 16px);
}
*/

button,
p[data-f-id="pbf"] {
	display: none;
}

.k-lesson-component {
	border:2px solid #ffa000;
	border-radius:7px;
	margin-top: 8px;
	color:#222;
	background-color:#fff;
}

.k-lesson-component-title {
	display:flex;
	cursor:pointer;
	font-size:16px;
	line-height:20px;
}

.k-lesson-component-title .k-lesson-component-toggle-holder {
	display:none;
}
.k-lesson-component-title .k-lesson-component-title-text {
	font-weight:bold;
	background-color:#ffe082;
	color:#222;
	padding:6px 6px;
	flex: 1 0 auto;
}
.k-lesson-component-title .k-lesson-component-title-text-0 {
	border-radius:6px 6px 0 0;
}
.k-lesson-component-title .k-lesson-component-title-weight {
	border-radius:6px 6px 0 0;
	background-color:#ef6c00;
	color:#fff;
	padding:6px 6px;
	flex:0 1 auto;
	text-align:right;
}

.k-lesson-grouping {
	border: 2px solid #ffa000;
	border-radius:6px;
	padding:8px;
	margin:12px 0;
}

.k-lesson-title-intro {
	font-weight:bold;
	font-size:16px;
	text-transform: uppercase;
	color:#000;
	margin:4px;
}

.k-lesson-component-guidance, .fr-view.k-lesson-component-guidance {
	color:#555;
	font-size:12px;
	line-height:15px;
	padding: 0 8px;
}

.k-lesson-component-content {
	color:#222;
	font-size:14px;
	line-height:19px;
	margin-top:12px;
	padding: 0 8px 8px 8px;
}
.k-lesson-component-content li {
	margin-bottom:6px;
}

.k-lesson-component-default-content {
	color:#555;
	font-size:12px;
	line-height:15px;
	margin-top:12px;
	padding: 0 8px;
}

.k-lesson-component-part-header {
	display:flex;
	align-items: center;
	font-weight:900;
	margin:12px 0 8px 0;
	background-color:#ffe57f;
	border-radius:6px;
	padding:3px 5px 3px 8px;
}

/* resource link in lesson plan text */
a.lesson-plan-text-resource-link {
	background-color:#555;
	color:#fff;
	padding:2px 8px;
	border-radius:5px!important;
	margin:0 4px!important;
	display:inline-block;
	height:20px;
	font-size:16px;
	cursor:pointer;
}

/* learning standards */
.k-lesson-standard-statement {
	margin: 10px;
}

.k-lesson-standard-statement {
	border-top:1px solid #ccc;
	padding-top: 5px;
}

@media print {
	.k-lesson-grouping {
	  page-break-inside: avoid;
	}
}

.k-lesson-resource-collection {
	display:flex;
	/*flex-wrap: wrap !important;*/
	flex-flow: row wrap;
	align-items: stretch;
}

.k-lesson-resource-collection > .k-resource-collection-item {
	display: flex;
	flex: 0 0 calc(33% - 8px);
	margin:4px;
	border:1px solid #999;
	border-radius:10px;
	/*position:relative;*/
}

.k-resource-collection-qr-code img {
	border-radius: 8px;
}

.k-resource-collection-link-wrapper {
	flex:1 1 auto;
	padding:4px 0 0 2px;
}

.k-resource-collection-item-icon {
	display: block;
	float: left;
	color: #000;
	margin: 2px 6px 0 4px;
    font-size: 14px!important;
}

.k-resource-collection-item-title-link {
	display: block;
	color: #000;
	font-size:12px;
	line-height:15px;
	text-decoration:none;
	margin:4px 0 4px 4px;
}

</style>
`

export default {
	props: {
		lesson: { type: Object, required: true },
		print_lesson_dom_id: {type: String, required: true},
	},
	data() { return {
		dialog_open: true,
		current_preview_settings: '',
		settings: { qr_codes: true },
		include_resources: false,
		print_html: '',
		pdf_url: '',
		resource_pdfs: '', // comma delimited list of pdf filenames
		printable_resource_count: 0,
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters([]),
		stringified_settings() {
			return JSON.stringify(this.settings)
		},
		changes_pending() {
			return this.current_preview_settings != this.stringified_settings
		},
	},
	watch: {
		include_resources() {
			// if/when the user says to include resources, generate the pdf if we haven't already done so
			if (this.include_resources) {
				if (!this.pdf_url) {
					this.generate_pdf()
				}
			} else {
				// if the user switches to *not* including resources, have to re-write the print view on nextTick (after the new iframe is showing)
				this.$nextTick(x=>this.write_print_view())
			}
		}
	},
	created() {
	},
	mounted() {
		vapp.print_view = this
		setTimeout(x=>this.write_print_view())
	},
	methods: {
		print() {
			if (!this.include_resources) {
				window.print_view_no_resources.print()
			} else {
				window.print_view_with_resources.print()
			}
		},

		open_in_new_window() {
			if (!this.include_resources) {
				let w = window.open()
				this.write_print_view(w)
			} else {
				window.open(this.pdf_url, 'pdf_url_window')
			}
		},

		write_print_view(w) {
			if (!w || !w.document) {
				w = window.print_view_no_resources
				this.current_preview_settings = this.stringified_settings
			}

			w.document.open()
			w.document.write('<!DOCTYPE html><html lang="en"><head>')
			w.document.write(sr('<title>$1</title>', this.lesson.lesson_title))
			w.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.min.css" integrity="sha384-GvrOXuhMATgEsSwCs4smul74iXGOixntILdUW9XmUC6+HX0sLNAK3q71HotJqlAn" crossorigin="anonymous">')
			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.10/css/froala_style.min.css" crossorigin="anonymous">')

			// w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" crossorigin="anonymous">')
			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/solid.min.css" integrity="sha512-qzgHTQ60z8RJitD5a28/c47in6WlHGuyRvMusdnuWWBB6fZ0DWG/KyfchGSBlLVeqAz+1LzNq+gGZkCSHnSd3g==" crossorigin="anonymous" referrerpolicy="no-referrer" />')
			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/fontawesome.min.css" integrity="sha512-xX2rYBFJSj86W54Fyv1de80DWBq7zYLn2z0I9bIhQG+rxIF6XVJUpdGnsNHWRa6AvP89vtFupEPDP8eZAtu9qA==" crossorigin="anonymous" referrerpolicy="no-referrer" />')

			w.document.write('<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,400;1,900&display=swap" rel="stylesheet">')

			w.document.write(pi_styles)

			w.document.write('</head><body>')

			this.write_lesson(w, this.lesson)

			// this.write_header_and_footer(w)

			w.document.write('</body></html>')

			this.print_html = sr('<!DOCTYPE html>$1', w.document.documentElement.outerHTML)

			w.document.close()
		},

		write_lesson(w, lesson) {
			// get html/css from LessonView component, then adapt for printing or pdf generation
			let html = sr('<div class="k-print-view-lesson-title">$1</div>', lesson.lesson_title)
			this.printable_resource_count = 0

			// clone in LessonView dom to new document
			// update nodes for print view, write to the print document w, have html available for pdf generation
			let print_html_doc = document.implementation.createHTMLDocument()
			print_html_doc.body.appendChild(document.getElementById(this.print_lesson_dom_id).cloneNode(true))

			let resources = this.lesson.resources
			//console.log('lesson.resources'); console.log(resources)

			// remove buttons from print view
			let buttons = [...print_html_doc.getElementsByTagName('button')]
			for (let i = 0; i < buttons.length; ++i) { buttons[i].remove() }

			// get ordered list of resource link DOM elements in lesson plan sections and lesson resources secion
			let lesson_plan_resource_links = [...print_html_doc.querySelectorAll('.k-lesson-component-resource-link')]
			// let lesson_section_resource_ids = []

			// go through each lesson plan icon link
			for (let i = 0; i < lesson_plan_resource_links.length; ++i) {
				let lp_el = lesson_plan_resource_links[i]
				// get the resource_id from app.open_resource_link(id)
				let lp_el_onclick = lp_el.getAttribute('onclick')
				if (lp_el_onclick.search(/^vapp.open_resource_link\('([\w-]+)'.*\)/) > -1) {
					// get the corresponding resource object
					let resource_id = RegExp.$1
					let lr_object = resources.find(x=>x.resource_id == resource_id)
					if (!lr_object) {
						// this in theory shouldn't happen, but if it does, skip the link
						console.log('PRINT LESSON: COULDN’T FIND RESOURCE OBJECT FOR RESOURCE *LINK*')
						continue
					}
					let lr_url = lr_object.full_url()

					// replace lesson plan link with updates, add icon class below
					let lp_link = print_html_doc.createElement('a')
					lp_link.setAttribute('class', 'lesson-plan-text-resource-link')
					lp_link.setAttribute('href', lr_url)
					lp_link.setAttribute('target', '_blank')

					let lp_link_icon = print_html_doc.createElement('i')
					lp_link_icon.setAttribute('class', 'fa ' + lr_object.icon())
					lp_link.appendChild(lp_link_icon)

					// replace the link with the updated element
					lp_el.parentNode.replaceChild(lp_link, lp_el)

				} else {
					console.log(sr('Resource id not found in $1', lp_el_onclick))
				}
			}

			let standard_divs = [...print_html_doc.querySelectorAll('[data-case-identifier')]
			// console.log('standard_divs', standard_divs)
			for (let i = 0; i < standard_divs.length; ++i) {
				let standard = this.lesson.standards[i]
				let s = ''
				if (standard.humanCodingScheme) s += `**${standard.humanCodingScheme}** `
				s += standard.fullStatement
				s = U.marked_latex(s)
				s = `<ul style="margin:4px 0 0 0"><li>${s}</li></ul>`
				$(standard_divs[i]).html(s)
			}

			// now process the resource divs at the bottom of the printout, creating the list of pdfs to print as we go
			this.resource_pdfs = ''
			this.printable_resource_count = 0
			let resource_divs = [...print_html_doc.querySelectorAll('.k-resource-collection-activity-wrapper')]
			for (let i = 0; i < resource_divs.length; ++i) {
				// the resource divs are guaranteed to be in the same order as the lesson resources
				let lr_el = resource_divs[i]
				let lr_object = resources[i]
				if (!lr_object) {
					// this in theory shouldn't happen, but if it does...
					console.log('PRINT LESSON: COULDN’T FIND RESOURCE OBJECT FOR RESOURCE *DIV*')
					continue
				}

				let lr_url = lr_object.full_url()

				// simplify a bit from LessonView resource div, no menu, may contain qr code
				let rec_div = print_html_doc.createElement('div')
				rec_div.setAttribute('class', 'k-resource-collection-item')

				let lr_link_wrapper = print_html_doc.createElement('div')
				lr_link_wrapper.setAttribute('class', 'k-resource-collection-link-wrapper')

				// replace lesson resources divs with links and qr codes
				let lr_link = lr_el.querySelector('a.k-resource-collection-item-title-link')
				lr_link.setAttribute('href', lr_url)
				lr_link.innerHTML = lr_object.description
				lr_link.setAttribute('target', '_blank')

				let lr_link_icon = print_html_doc.createElement('i')
				lr_link_icon.setAttribute('class', 'k-resource-collection-item-icon fa ' + lr_object.icon())

				lr_link_wrapper.appendChild(lr_link_icon)
				lr_link_wrapper.appendChild(lr_link)
				rec_div.appendChild(lr_link_wrapper)

				// https://larsjung.de/kjua/
				// alternative to qrcode.vue which can used outside of <template>
				if (this.settings.qr_codes) {
					let qr = kjua({text: lr_url, size: 80})
					let qr_div = print_html_doc.createElement('a')
					qr_div.appendChild(qr)
					qr_div.setAttribute('class', 'k-resource-collection-qr-code')
					qr_div.setAttribute('href', lr_url)
					qr_div.setAttribute('target', '_blank')
					rec_div.appendChild(qr_div);
				}

				// replace with the updated resource div
				lr_el.parentNode.replaceChild(rec_div, lr_el);

				// add to resource_pdfs if this is an uploaded pdf
				if (lr_url.indexOf('.pdf') > 0 && lr_object.type === 'upload') {
					let path_parts = lr_url.split('/')
					this.resource_pdfs += path_parts[path_parts.length-1] + ','
					++this.printable_resource_count
				}
			}

			html += print_html_doc.getElementById(this.print_lesson_dom_id).innerHTML
			html = html.replace(/display:\s*none/g, '')
			html = html.replace(/<!---->/g, '')
			html = html.replace(/<(\S)/g, "\n<$1")

			w.document.write(html)
		},

		// server side pdf writer to append resource pdfs to lesson
		generate_pdf() {
			let payload = {
				'print_doc_type': 'lesson',
				'print_view_html': this.print_html,
				'print_to_pdf': 'yes',
			}

			// if including resources in printed lesson, send comma delimited string of resource pdfs
			if (this.include_resources && this.printable_resource_count > 0) {
				payload.resource_pdfs = (this.resource_pdfs).slice(0, -1) // trim trailing comma
			}

			this.$store.dispatch('save_to_pdf', payload).then((result)=>{
				if (result.status != 'ok') {
					console.log('print_to_pdf service failed')
				} else {
					this.pdf_url = sr('$1//$2/user-files/lesson-downloads/$3', location.protocol, location.host, result.pdf_file)
				}
			}).catch((e)=>{
				console.log(e)
			})
		},

		write_header_and_footer(w) {
			let header = 'This is the header'
			let footer = 'This is the footer...'
			w.document.write(sr('<div class="header">$1</div>', header))
			w.document.write(sr('<div class="footer">$1</div>', footer))
		},
	}
}
</script>

<style lang="scss">
.k-print-settings {
	height:calc(100vh - 120px);
	overflow:auto;
	flex:0 1 350px;
	margin-right:12px;
	border:1px solid #ccc;
	border-radius:12px;
	padding:0 12px 12px 12px;
	background-color:#fff;
}

.k-print-preview {
	border-radius:12px;
	padding:12px 12px 4px 12px;
	margin-bottom:2px;
	background-color:#ccc;
	flex:1 0 auto;
}

.k-print-view-iframe {
	background-color:#fff;
	border-radius:3px;
	width:100%;
	height:calc(100vh - 182px);
	border:1px solid black;
}

.k-lesson-print-close-btn {
	position:absolute;
	right:-8px;
	top:-8px;
	background-color:#fff;
	z-index:1000;
}

</style>
