<!-- Part of the SPARKL educational activity system, Copyright 2024 by Pepper Williams -->
<template><div class="k-lpe-lesson-edit"><div v-if="initialized">

	<!-- note magic constant for padding max-height, so that editor scrolls properly -->
	<div style="font-size:16px; line-height:20px; padding-bottom:48px; overflow:auto"> <!-- ; max-height:calc(100vh - 168px); -->
		<div class="k-lpe-alba-outer-outer mt-2">
			<!-- MENU FOR LESSON COMPONENTS/STAGES -->
			<div class="k-lpe-alba-menu-stages">
				<v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer" :class="menu_stage_css('title')" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage amber lighten-4" @click="go_to_stage('title')">
						<v-icon style="width:20px" color="amber darken-4" small class="mr-2">fas fa-calendar-day</v-icon>
						<div class="k-lpe-alba-menu-stage-label">Title/Date</div>
						<v-spacer/>
						<v-icon>{{menu_stage_icon('title')}}</v-icon>
					</div>
				</div></v-hover>

				<v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer" :class="menu_stage_css('standards')" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage amber lighten-4" @click="go_to_stage('standards')">
						<v-icon style="width:20px" color="amber darken-4" small class="mr-2">fas fa-map</v-icon>
						<div class="k-lpe-alba-menu-stage-label">Learning Standards</div>
						<v-spacer/>
						<v-icon>{{menu_stage_icon('standards')}}</v-icon>
					</div>
				</div></v-hover>

				<div v-for="(component, ci) in lesson_plan" :key="component.lc_uuid"><v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer" :class="menu_stage_css(component)" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage" :class="`${mdlph[component.lc_uuid].lc_color} lighten-4`" @click="go_to_stage(component)">
						<v-icon style="width:20px" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" small class="mr-2">{{mdlph[component.lc_uuid].lc_icon}}</v-icon>
						<div class="k-lpe-alba-menu-stage-label">{{mdlph[component.lc_uuid].lc_title}}</div>
						<v-spacer/>
						<v-icon>{{menu_stage_icon(component)}}</v-icon>
					</div>
				</div></v-hover></div>

				<v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer" :class="menu_stage_css('sparkl')" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage purple lighten-4" @click="go_to_stage('sparkl')">
						<v-icon style="width:20px" color="purple darken-4" small class="mr-2">fas fa-star</v-icon>
						<div class="k-lpe-alba-menu-stage-label">{{site_config.sparkl_app_name}} Student Activity</div>
						<v-spacer/>
						<v-icon>{{menu_stage_icon('sparkl')}}</v-icon>
					</div>
				</div></v-hover>

				<v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer" :class="menu_stage_css('resources')" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage purple lighten-4" @click="go_to_stage('resources')">
						<v-icon style="width:20px" color="purple darken-4" small class="mr-2">fas fa-shapes</v-icon>
						<div class="k-lpe-alba-menu-stage-label">Other Lesson Resources<span v-if="edited_lesson.resources.length>0"> ({{edited_lesson.resources.length}})</span></div>
						<!-- <v-spacer/>
						<v-icon>{{menu_stage_icon('resources')}}</v-icon> -->
					</div>
				</div></v-hover>
			</div>

			<!-- HOLDER FOR EACH STAGE, INCLUDING TITLE, STANDARDS, SPARKL ACTIVITY, AND RESOURCES -->
			<div class="k-lpe-alba-content"><div class="k-lpe-alba-content-inner">
				<!-- TITLE/DATE -->
				<div class="k-lpe-alba-content-section k-lpe-alba-content-section-title amber lighten-4" style="display:block; border-bottom:1px solid #ccc!important; border-radius:0 0px 0 0;">
					<div class="d-flex align-center py-1">
						<div style="flex:1 0 auto"><v-text-field ref="title_textfield" background-color="#fff" style="font-weight:bold" dense hide-details outlined :label="lesson_noun+' Title'" v-model="edited_lesson.lesson_title"></v-text-field></div>
						<div v-if="lesson_class!='template'" class="ml-2 mr-2" style="flex:0 0 172px">
							<v-menu ref="lesson_date_menu" v-model="lesson_date_menu" :close-on-content-click="false" :return-value.sync="edited_lesson.lesson_date" transition="scale-transition" offset-y min-width="290px">
								<template v-slot:activator="{on}"><v-text-field background-color="#fff" outlined dense hide-details v-model="lesson_date_formatted" label="Lesson Date" append-icon="fas fa-calendar-day" @click:append="lesson_date_menu=true" readonly v-on="on"></v-text-field></template>
								<v-date-picker v-model="edited_lesson.lesson_date" no-title scrollable>
									<v-btn text color="primary" @click="no_lesson_date">No Lesson Date</v-btn>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="lesson_date_menu=false">Done</v-btn>
								</v-date-picker>
							</v-menu>
						</div>
					</div>
				</div>

				<!-- STANDARDS -->
				<div class="k-lpe-alba-content-section k-lpe-alba-content-section-standards amber lighten-4" style="display:block; border-bottom:1px solid #ccc!important">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" color="amber darken-4" @click="edited_lesson.standards_showing=!edited_lesson.standards_showing">fas fa-map</v-icon>
						<div class="k-lpe-alba-section-header-text mr-2" @click="edited_lesson.standards_showing=!edited_lesson.standards_showing">Learning Standards</div>
						<v-btn v-if="standards.length>0" small icon class="mr-2" color="amber darken-4" @click="edited_lesson.standards_showing=!edited_lesson.standards_showing"><v-icon style="font-size:20px">fas {{edited_lesson.standards_showing?'fa-angles-up':'fa-angles-down'}}</v-icon></v-btn>
						<draggable v-bind="drag_options" v-model="standards" @end="drag_complete" class="d-flex flex-wrap my-1">
							<CASEItemBtn v-for="(s) in standards" :key="s.identifier" btn_color="amber darken-4" :item="s" @click="align_to_standard(s)" @delete="remove_standard(s)" small :show_move_icon="standards.length>1" show_delete_icon :outer_class="'ma-1'" />
						</draggable>
						<v-btn small color="#555" class="ma-1" dark @click="align_to_standard(null)"><v-icon class="mr-1" small>fas fa-plus</v-icon>Add</v-btn>
						<div v-if="standards.length==0" class="k-lpe-alba-section-instructions" style="display:block"><p>Choose the learning standard(s) you will cover in this lesson.</p></div>
					</div>
					<v-expand-transition><div v-show="edited_lesson.standards_showing">
						<div v-for="(standard) in standards" class="d-flex">
							<CASEItemBtn btn_color="amber darken-4" :item="standard" @click="align_to_standard(standard)" small :outer_class="'mr-2'" />
							<div v-html="standard.fullStatement"></div>
						</div>
					</div></v-expand-transition>
					<div class="k-lpe-alba-section-buttons" v-if="edited_lesson.standards_showing&&standards.length>0&&!standards_done_clicked">
						<v-btn small color="#444" dark @click="stage_done_clicked('standards')"><v-icon style="font-size:20px" class="mr-2">far fa-circle-check</v-icon> Done</v-btn>
					</div>
				</div>
				
				<!-- LESSON COMPONENTS -->
				<div v-for="(component, ci) in lesson_plan" :key="component.lc_uuid" class="k-lpe-alba-content-section" :class="section_stage_css(component)">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" @click="toggle_stage(component)" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`">{{mdlph[component.lc_uuid].lc_icon}}</v-icon>
						<div class="k-lpe-alba-section-header-text" :class="`${mdlph[component.lc_uuid].lc_color}--text text--darken-4`" @click="toggle_stage(component)">{{mdlph[component.lc_uuid].lc_title}}</div>
						<v-btn small icon class="ml-3" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" @click="toggle_stage(component)"><v-icon :style="component.lc_open_for_editing?(component.lc_content?'font-size:22px':'font-size:18px'):'font-size:20px'">{{component.lc_open_for_editing?(component.lc_content?'far fa-circle-check':'fas fa-forward'):'fas fa-edit'}}</v-icon></v-btn>
						<div class="k-lpe-alba-section-instructions" v-html="mdlph[component.lc_uuid].lc_guidance"></div>
					</div>
					<div class="k-lpe-alba-section-summary" v-html="section_text(component)">	<!--  :class="`${mdlph[component.lc_uuid].lc_color} lighten-5`" -->
					</div>

					<!-- SUGGESTIONS -->
					<v-expand-transition><div class="k-lpe-alba-section-editor" v-show="component.lc_open_for_editing">
						<div class="mt-2"><froala-wrapper :ref="`froala_${component.lc_uuid}`" :config="editor_config(component)" :parameter_object="component" parameter="lc_content" v-model="component.lc_content" /></div>
						<div class="text-center mt-3 mb-2"><span v-if="component.lc_suggestions.length>0" class="white py-1 px-2" style="border-radius:5px; color:#666;"><i>Click <v-icon style="font-size:18px; margin-top:-2px" color="green darken-4">fas fa-circle-check</v-icon> to include suggestions in your lesson plan</i></span></div>
						<div v-for="(option, index) in component.lc_suggestions" :key="index">
							<div v-if="option=='---'" style="line-height:0px; border-top:1px solid #aaa"></div>
							<v-hover v-else v-slot:default="{hover}">
								<div class="k-lpe-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="component_suggestion_html(component, option)"></div>
								<v-btn small icon class="mr-1 ml-1" @click="add_suggestion(component, index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="remove_suggestion(component, index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
							</div></v-hover>
						</div>
						<div class="k-lpe-alba-section-buttons">
							<v-btn v-if="component.lc_content&&!mdlph[component.lc_uuid].lc_gpt_model_prompt" small color="#444" dark @click="stage_done_clicked(component)"><v-icon style="font-size:20px" class="mr-2">far fa-circle-check</v-icon> Done</v-btn>
							<v-btn v-if="!component.lc_content" small color="#444" dark @click="stage_done_clicked(component)">Skip<v-icon small class="ml-2">fas fa-forward</v-icon></v-btn>
							<v-spacer/>
							<v-btn small class="k-tight-btn mr-2" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" dark :style="!stage_suggestible(component)?'opacity:0.5':''" @click="make_component_suggestions(component)"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{component.lc_content||component.lc_suggestions.length>0?' More':''}} {{mdlph[component.lc_uuid].lc_title_plural}}</v-btn>
							<v-btn v-if="component.lc_suggestions.length>0" small class="k-tight-btn elevation-0" color="red darken-4" dark @click="clear_suggestions(component)"><v-icon small class="mr-1">fas fa-xmark</v-icon>Clear all Suggestions</v-btn>
						</div>
					</div></v-expand-transition>

					<!-- MODEL RESPONSES FOR QUESTIONS -->
					<div v-if="mdlph[component.lc_uuid].lc_gpt_model_prompt&&component.lc_content" v-show="component.lc_open_for_editing" class="mt-2">
						<div style="font-size:14px" class="ml-1"><b>Model response for question (optional):</b></div>
						<div class="mt-1"><froala-wrapper :ref="`froala_model_response_${component.lc_uuid}`" :config="model_response_editor_config(component)" :parameter_object="component" parameter="lc_sparkl_model_response" v-model="component.lc_sparkl_model_response" /></div>
						<div v-for="(option, index) in component.lc_model_suggestions" :key="index"><v-hover v-slot:default="{hover}"><div class="k-lpe-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
							<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
							<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
							<v-btn small icon class="mr-1 ml-1" @click="add_model_suggestion(component, index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							<v-btn small icon class="mr-1 ml-1" @click="remove_model_suggestion(component, index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
						</div></v-hover></div>
						<div class="k-lpe-alba-section-buttons">
							<v-btn small color="#444" dark @click="stage_done_clicked(component)"><v-icon style="font-size:20px" class="mr-2">far fa-circle-check</v-icon> Done</v-btn>
							<v-spacer/>
							<v-btn small class="k-tight-btn mr-2" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" dark @click="make_response_suggestion(component)"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{component.lc_sparkl_model_response||component.lc_model_suggestions.length>0?' Another':''}} Model Response</v-btn>
						</div>
					</div>
				</div>

				<!-- SPARKL ACTIVITY -->
				<div class="k-lpe-alba-content-section k-lpe-alba-content-section-sparkl purple lighten-5" v-if="sparkl_creatable" style="display:block; border-bottom:1px solid #ccc!important">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" color="purple darken-4" @click="edited_lesson.sparkl_showing=!edited_lesson.sparkl_showing;scroll_to_stage('sparkl')">fas fa-star</v-icon>
						<div class="k-lpe-alba-section-header-text purple--text text--darken-4 mr-2 py-2" @click="edited_lesson.sparkl_showing=!edited_lesson.sparkl_showing;scroll_to_stage('sparkl')">{{site_config.sparkl_app_name}} Student Activity</div>
						<v-btn small icon class="mr-2" color="purple darken-4" @click="edited_lesson.sparkl_showing=!edited_lesson.sparkl_showing;scroll_to_stage('sparkl')"><v-icon style="font-size:20px">fas {{edited_lesson.sparkl_showing?'fa-angles-up':'fa-angles-down'}}</v-icon></v-btn>
						<div v-if="sparkl_activity_created" class="ml-3"><b>Created:</b> Activity ID {{edited_lesson.sparkl_activity_id}}</div>
						<v-spacer/>
						<v-btn v-if="sparkl_activity_created" small color="#444" class="mr-2" dark @click="open_sparkl_activity"><v-icon small class="mr-2">fas fa-up-right-from-square</v-icon> Open Activity</v-btn>
					</div>
					<v-expand-transition><div v-show="edited_lesson.sparkl_showing" style="background-color:#fff; padding:8px; border-radius:6px; margin-top:4px;">
						 <div class="text-center mx-auto" style="max-width:720px; font-size:14px;"><v-icon color="light-blue" small class="mr-1" style="margin-top:-2px">fas fa-info-circle</v-icon><i>
							<span v-if="edited_lesson.sparkl_activity_id==0">To create a {{site_config.sparkl_app_name}} student activity to accompany this lesson plan, choose the lesson components you would like to create exercises for, then click <nobr>“CREATE ACTIVITY”</nobr> below. You will then be able to modify and continue building the activity, then administer the activity with your students, using the {{site_config.sparkl_app_name}} teacher interface.</span>
							<span v-else>You can update the {{site_config.sparkl_app_name}} student activity that accompanies this lesson plan by clicking <nobr>“UPDATE ACTIVITY”</nobr> below. You can modify and continue building the activity, then administer the activity with your students, using the {{site_config.sparkl_app_name}} teacher interface.</span>
						</i></div>
						 <div v-for="(component, ci) in lesson_plan" :key="component.lc_uuid" v-if="mdlph[component.lc_uuid].lc_offer_to_include_in_sparkl&&component.lc_content" class="ml-6">
							<div class="d-flex align-start mt-2 pt-2" style="border-top:1px solid #ccc">
								<div style="flex:0 0 200px"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="component.lc_include_in_sparkl"><template v-slot:label><b style="color:#000">{{mdlph[component.lc_uuid].lc_title}}</b></template></v-checkbox></div>
								<div style="font-size:14px; line-height:18px;">{{sparkl_include_help_text(mdlph[component.lc_uuid].lc_sparkl_include_help_text)}}</div>
							</div>
						</div>
						<div class="d-flex mt-2 pt-2" style="border-top:1px solid #ccc">
							<v-btn small color="#444" dark @click="edited_lesson.sparkl_showing=false"><v-icon style="font-size:20px" class="mr-2">far fa-circle-check</v-icon> Done</v-btn>
							<v-spacer/>
							<v-btn small color="purple darken-4" dark @click="create_sparkl_activity"><v-icon small class="mr-2">fas fa-star</v-icon> {{(edited_lesson.sparkl_activity_id==0) ? 'Create' : 'Update'}} Activity</v-btn>
						</div>
					</div></v-expand-transition>
				</div>

				<!-- OTHER RESOURCES -->
				<div class="k-lpe-alba-content-section k-lpe-alba-content-section-resources purple lighten-5" :style="show_resources_component?'display:block':''">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" color="purple darken-4" @click="edited_lesson.resources_showing=!edited_lesson.resources_showing;scroll_to_stage('resources')">fas fa-shapes</v-icon>
						<div class="k-lpe-alba-section-header-text purple--text text--darken-4 mr-2 py-2" @click="edited_lesson.resources_showing=!edited_lesson.resources_showing;scroll_to_stage('resources')">Other Lesson Resources ({{edited_lesson.resources.length}})</div>
						<v-btn small icon class="mr-2" color="purple darken-4" @click="edited_lesson.resources_showing=!edited_lesson.resources_showing;scroll_to_stage('resources')"><v-icon style="font-size:20px">fas {{edited_lesson.resources_showing?'fa-angles-up':'fa-angles-down'}}</v-icon></v-btn>
					</div>
					<v-expand-transition><div v-show="edited_lesson.resources_showing&&edited_lesson.resources.length>0" class="k-lesson-resource-collection" style="background-color:#fff; padding:8px 8px; border-radius:6px; margin-top:12px;">
						<div v-for="(resource) in edited_lesson.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
							<ResourceCollectionItem
								:item="resource"
								:enable_resource_search="false"
								:enable_reorder="false"
								:lp_context="lp" :unit_context="unit"
								@edit_item_saved="edit_item_saved"
								@edit_item_cancel="edit_item_cancel"
								@remove_item="remove_item"
							/>
						</div>
					</div></v-expand-transition>
					<div v-show="edited_lesson.resources_showing" class="text-center mt-2">
						<v-btn small class="ml-2" color="#555" dark @click="create_resource_start"><v-icon small class="mr-1">fas fa-plus-circle</v-icon>Add Resource</v-btn>
						<v-btn v-show="edited_lesson.resources.length>1" small class="ml-2" color="#666" dark @click="resource_reorder_open=true"><v-icon x-small class="mr-1">fas fa-arrows-alt-v</v-icon>Reorder Resources</v-btn>

					</div>
				</div>
			</div></div>
		</div>

	</div>
	<div class="px-2 py-2 k-lpe-lesson-editor-buttons">
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="course_code" v-on="on" class="mr-2" fab x-small dark color="secondary" @click="$emit('shift_lesson')"><v-icon small>fas {{lesson_shifted?'fa-angles-left':'fa-angles-right'}}</v-icon></v-btn></template>Shift Lesson</v-tooltip>
		<v-btn color="secondary" class="k-tight-btn" @click="edit_lesson_cancel"><v-icon small class="mr-2">fas fa-times</v-icon>{{is_new_lesson?'Cancel':'Close Editor'}}&nbsp;</v-btn>

		<v-tooltip top v-if="show_gpt_stats"><template v-slot:activator="{on}"><div v-on="on" class="d-flex align-center ml-4 mr-2" style="font-size:14px;">
			{{edited_lesson.gpt_suggestion_accepts}} / {{edited_lesson.gpt_suggestion_requests}}
			<div class="grey darken-2 white--text px-2 mx-1" style="border-radius:4px">{{edited_lesson.gpt_input_tokens}} + {{edited_lesson.gpt_output_tokens}} = {{gpt_cost}}¢</div>
			<div style="width:160px"><v-select v-model="gpt_model" :items="gpt_model_options" label="Model" dense outlined hide-details></v-select></div>
			<div style="width:60px" class="mx-1"><v-text-field outlined dense hide-details label="Temp" v-model="gpt_temperature" placeholder=""></v-text-field></div>
			<v-checkbox class="ma-0 pa-0" v-model="edit_llm_suggestion_prompts" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"><template v-slot:label></template></v-checkbox>
		</div></template>{{edited_lesson.gpt_suggestion_accepts}} suggestions accepted / {{edited_lesson.gpt_suggestion_requests}} suggestion requests<br>Total cost for model <b>{{gpt_model}}</b>: {{gpt_cost}}¢ (<b>${{(gpt_cost * 1000 / 100).toFixed(2)}}</b> for 1000 similar lessons)<br><v-icon color="#fff" small style="margin-top:-3px">far {{edit_llm_suggestion_prompts?'fa-square-check':'fa-square'}}</v-icon> Edit suggestion prompts: <b>{{edit_llm_suggestion_prompts?'ON':'OFF'}}</b></v-tooltip>

		<v-spacer/> 
		<div v-show="just_saved" class="k-lpe-alba-saved-indicator mr-4"><v-icon color="#fff" style="margin-top:-4px; font-size:18px" class="mr-2">fas fa-save</v-icon>SAVED</div>
		<v-spacer/> 

		<!-- hidden btn to hide/show gpt stats -->
		<v-btn style="opacity:0" class="pa-0" x-small color="primary" @click="show_gpt_stats=!show_gpt_stats">X</v-btn>
		<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" x-small fab color="#666" dark class="ml-3" @click="give_feedback"><v-icon small>fas fa-comment</v-icon></v-btn></template>Provide feedback / report a bug</v-tooltip>
		<v-btn v-show="dirty" color="primary" class="ml-3 k-tight-btn" @click="save_lesson"><v-icon small class="mr-2">fas fa-save</v-icon>Save&nbsp;</v-btn>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_and_close"><v-icon small class="mr-2">fas fa-check</v-icon>Save and Close Editor</v-btn>
	</div>

	<!-- resource reorderer -->
	<v-dialog v-if="resource_reorder_open" v-model="resource_reorder_open" max-width="680" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900">Reorder Resources</b></v-card-title>
			<v-card-text class="pt-3" style="background-color:#eee">
				<draggable v-bind="{animation:300}" v-model="edited_lesson.resources" @end="save_lesson">
					<div v-for="(resource) in edited_lesson.resources" :key="resource.resource_id" style="border:1px solid #333; padding:4px; margin:4px 0; background-color:#fff; border-radius:6px; cursor:move" class="d-flex align-center">
						<div class="mx-2 k-lesson-editor-resource-reorderer-handle"><v-icon small>fas fa-arrows-alt-v</v-icon></div>
						<div style="font-size:14px;" v-html="resource.description"></div>
					</div>
				</draggable>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="resource_reorder_open=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ResourceSearch v-if="show_search" 
		:dialog_title="`Search for Resource${resource_search_home_lesson?'':'s'} to Add to Lesson`"
		:item_types="['resources']"
		:home_lesson="resource_search_home_lesson"
		:home_unit="unit"
		:home_collection="lp"
		:allow_add_from_home="true"
		:max_items="resource_search_home_lesson?1:0"
		:existing_resources="resource_search_home_lesson?[]:edited_lesson.resources"
		:adding_to_lesson="true"
		@add_items_from_search="add_items_from_search" @dialog_cancel="search_cancel" 
	/> <!-- if we're adding to froala text, we want to give the user the option of choosing resources from this lesson, and we don't want to show existing resources -->

</div></div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import FroalaResourcesMixin from '../../js/FroalaResourcesMixin'
import CASEItemBtn from '../standards/CASEItemBtn'
import '../resources/from_sparkl/auto-blank-utilities.js'
import '../resources/from_sparkl/Exercise.js'

export default {
	name: 'LessonEditor',
	components: { 
		ResourceCollectionItem: () => import('../resources/ResourceCollectionItem'),
		ResourceSearch: () => import('../resources/ResourceSearch'),
		draggable, CASEItemBtn,  },
	mixins: [FroalaResourcesMixin],
	props: {
		original_lesson: { required: true },	// if 'new' we're creating a new lesson
		lesson_class: { type: String, required: false, default() { return '' }},	// 'teacher' or 'template'; masters have their own editor
		course_code: { type: String, required: false, default() { return '' }},
		lp_unit_id: { type: Number, required: false, default() { return 0 }},
		lesson_shifted: { type: Boolean, required: false, default() { return false }}
	},
	data() { return {
		initialized: false,
		edited_lesson: null,
		lesson_master: null,
		resource_being_edited: false,
		standards_showing: false,
		show_search: false,
		resource_search_home_lesson: null,

		student_description_showing: false,
		lesson_components_showing: true,
		resources_showing: false,
		standards_showing: false,

		resource_reorder_open: false,

		lesson_date_menu: false,

		stringified_original_lesson: '',

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		},

		// https://openai.com/api/pricing/
		gpt_model_options: [
			{ value: 'gpt-4o-mini', text: 'gpt-4o-mini', input_cost:0.150, output_cost:0.600 },
			{ value: 'gpt-4o', text: 'gpt-4o', input_cost:5.000, output_cost:15.000 },
			{ value: 'gpt-3.5-turbo-0125', text: 'gpt-3.5-turbo-0125', input_cost:0.500, output_cost:1.500 },
			{ value: 'gpt-4-turbo', text: 'gpt-4-turbo', input_cost:10.000, output_cost:30.000 },
		],
		// gpt_temperature: 0.4,
		// gpt_temperature: 1,		// 0-2; 0 = less variance; defaults to 1
		gpt_frequency_penalty: 0,	// this is the default; setting this to non-0 would do something similar to gpt_temperature
		suggestions_to_request: 5,

		just_saved: false,
		alba_warning_issued: false,
		standards_done_clicked: false,

		// sparkl_origin_override: 'https://dev.sparkl-ed.com',
		sparkl_closed_from_embed: false,
		force_reload_sparkl: false,
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'lesson_masters']),
		...mapGetters([]),
		// alba_warning_issued: {
		// 	get() { return this.$store.state.lst.alba_warning_issued },
		// 	set(val) { this.$store.commit('lst_set', ['alba_warning_issued', val]) }
		// },
		gpt_model: {
			get() { return this.$store.state.lst.gpt_model },
			set(val) { this.$store.commit('lst_set', ['gpt_model', val]) }
		},
		gpt_temperature: {
			get() { return this.$store.state.lst.gpt_temperature },
			set(val) { this.$store.commit('lst_set', ['gpt_temperature', val]) }
		},
		dirty() {
			let s = JSON.stringify(this.edited_lesson.copy_for_save())
			return (s != this.stringified_original_lesson)
		},
		mdlph() {
			// master data lesson plan hash: convenience object for getting properties of the master data for each component
			let o = {}
			for (let c of this.lesson_master.lesson_plan) {
				o[c.lc_uuid] = c
			}

			// for converted lessons, also include a value for each converted component
			for (let c of this.edited_lesson.lesson_plan) {
				if (!o[c.lc_uuid]) {
					console.warn('here', object_copy(c))
					o[c.lc_uuid] = {
						lc_title: c.lc_title || 'Lesson Component',
						lc_title_plural: c.lc_title || 'Lesson Component',
						lc_icon: 'fas fa-list-ul',
						lc_color: 'grey',
						lc_offer_to_include_in_sparkl: true,
						lc_sparkl_include_method: 'basic_content',
						lc_sparkl_include_help_text: 'The text you have entered, including any resource links, will be added as a Freeform exercise, which you can edit as needed in the $sparkl_app_name.',
					}
				}
			}
			return o
		},
		lesson_course_code() {
			if (this.original_lesson.course_code) return this.original_lesson.course_code
			else return this.course_code
		},
		lesson_lp_unit_id() {
			if (this.original_lesson.lp_unit_id) return this.original_lesson.lp_unit_id
			else return this.lp_unit_id
		},
		lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.lesson_course_code) },
		unit() {
			if (!this.lp || this.lesson_lp_unit_id == 0) return null
			return this.lp.units.find(x=>x.lp_unit_id == this.lesson_lp_unit_id)
		},
		subject() {
			if (!this.lp) {
				// console.log('LessonEditor: no lp, so can’t get subject')
				return null
			}
			return this.lp.subject
		},
		case_framework_identifier() { return this.$store.state.subjects[this.subject]?.framework_identifier },	// may return null
		case_framework() { return this.$store.state.case_frameworks[this.case_framework_identifier] },
		lesson_noun() {
			if (this.lesson_class == 'master') return 'Lesson Master'	// not used here
			else if (this.lesson_class == 'template') return 'Template Lesson'
			else return 'Lesson'
		},
		is_new_lesson() { return this.original_lesson == 'new' || this.original_lesson.lesson_id == 0  },
		lesson_date_formatted() {
			if (empty(this.edited_lesson)) return ''
			if (empty(this.edited_lesson.lesson_date)) return ''
			let d = new Date(this.edited_lesson.lesson_date + 'T00:00:00')
			return date.format(d, 'ddd MMM D')	// Tue Jan 9
		},
		edit_llm_suggestion_prompts: {
			get() { return this.$store.state.lst.edit_llm_suggestion_prompts },
			set(val) { this.$store.commit('lst_set', ['edit_llm_suggestion_prompts', val]) }
		},
		// vapp.lesson_editor_component.show_gpt_stats = true
		show_gpt_stats: {
			get() { return this.$store.state.lst.show_gpt_stats },
			set(val) { this.$store.commit('lst_set', ['show_gpt_stats', val]) }
		},
		gpt_cost() {
			let md = this.gpt_model_options.find(x=>x.value == this.gpt_model)
			let x = (this.edited_lesson.gpt_input_tokens * md.input_cost) + (this.edited_lesson.gpt_output_tokens * md.output_cost)
			x = x / 1000000
			x = x * 100
			return x.toFixed(3)
		},
		suggestible_component_open_for_editing() {
			if (empty(this.lesson_plan)) return false
			for (let c of this.lesson_plan) {
				if (c.lc_open_for_editing) return true
			}
			return false
		},
		chosen_standard_identifiers() {
			if (!this.edited_lesson) return []

			let arr = []
			for (let s of this.standards) {
				arr.push(s.identifier)
			}
			return arr
		},
		standards() { return this.edited_lesson?.standards },
		lesson_plan() { return this.edited_lesson?.lesson_plan },
		sparkl_creatable() {
			for (let c of this.lesson_plan) {
				if (c.lc_status == 'complete') return true
			}
			return false
		},
		sparkl_activity_created() { return this.edited_lesson.sparkl_activity_id != 0 },
		show_resources_component() {
			if (!this.edited_lesson) return false
			if (this.edited_lesson.resources_showing) return true
			if (this.edited_lesson.resources.length > 0) return true
			for (let c of this.lesson_plan) {
				if (c.lc_status == 'complete') return true
			}
			return false
		},
	},
	watch: {
		chosen_standard_identifiers() {
			// console.log('chosen_standard_identifiers updated! ' + this.chosen_standard_identifiers.length)
			// if we're showing vapp.case_tree_component and the standard_identifiers get updated, push the change...
			if (vapp.case_tree_component && vapp.case_tree_component.set_chosen_items) {
				vapp.case_tree_component.set_chosen_items(this.chosen_standard_identifiers)
			}
		},
		'edited_lesson.lesson_date'(val) {
			if (this.$refs.lesson_date_menu) this.$refs.lesson_date_menu.save(this.edited_lesson.lesson_date)
		},
	},
	created() {
	},
	mounted() {
		console.warn('mounting LEE: ', object_copy(this.original_lesson))
		// TEMP: hard-code lesson master
		this.lesson_master = window.variant_b_lesson_master_data

		if (this.is_new_lesson) {
			// start with what was passed in via the original_lesson
			this.edited_lesson = new Lesson(this.original_lesson, this.lesson_master)

			// set the new lesson's course_code and lp_unit_id to what we received
			this.edited_lesson.course_code = this.course_code
			this.edited_lesson.lp_unit_id = this.lp_unit_id

			setTimeout(x=>{
				// this.$refs.title_textfield.select()
				$(this.$refs.title_textfield.$el).find('input').select()
				$(this.$refs.title_textfield.$el).find('input').focus()
			}, 500)

		} else {
			this.edited_lesson = new Lesson(this.original_lesson, this.lesson_master)
		}

		// if at least one standard is chosen, set the first not-completed, not-skipped stage as open_for_editing
		if (this.standards.length > 0) {
			this.open_next_component_for_editing()
			// also show the standards, and set standards_done_clicked to true in this case
			this.edited_lesson.standards_showing = true
			this.standards_done_clicked = true
		}

		this.stringified_original_lesson = JSON.stringify(this.edited_lesson.copy_for_save())

		// this used by the standards chooser hide_fn
		vapp.lesson_editor_component = this

		this.initialized = true
	},
	methods: {
		master_component(component) {
			return this.mdlph[component?.lc_uuid || component]
		},

		toggle_component(ci, val) {
			if (typeof(val) != 'boolean') val = !this.lesson_plan[ci].lc_showing
			this.lesson_plan[ci].lc_showing = val
		},

		editor_config(component) {
			let o = {
				placeholderText: '',
				// initOnClick: true,
				toolbarInline: false,
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
				zIndex: 1000,	// needed for to make table menus show up properly
			}
			// if lc_text_format isn't 'paragraphs' (i.e. 'line' or 'list'), use <br>'s as line separators
			let mc = this.master_component(component)
			if (mc?.lc_text_format != 'paragraphs') o.enter = FroalaEditor.ENTER_BR

			let config = U.get_froala_config(o)

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			config.toolbarButtons.moreRich.buttonsVisible += 1
			// and remove the 'insertHR' btn, if there
			let i = config.toolbarButtons.moreRich.buttons.findIndex(x=>x=='insertHR')
			if (i > -1) {
				config.toolbarButtons.moreRich.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreRich.buttonsVisible-1)) config.toolbarButtons.moreRich.buttonsVisible -= 1
			}
			// also remove the 'paragraphFormat' btn from the moreMisc buttons
			i = config.toolbarButtons.moreMisc.buttons.findIndex(x=>x=='paragraphFormat')
			if (i > -1) {
				config.toolbarButtons.moreMisc.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreMisc.buttonsVisible-1)) config.toolbarButtons.moreMisc.buttonsVisible -= 1
			}

			return config
		},

		model_response_editor_config(component) {
			// modeled on sparkl window.cr_froala_config
			let toolbarButtons = {
				moreMisc: {buttons: ['bold', 'italic', 'formatUL', 'formatOL', 'emoticons', 'insertLink', 'insertmathtype', 'undo', 'redo', 'clearFormatting', 'fullscreen',
					'underline', 'strikeThrough', 'align', 'sparklfontsize', 'textColor', 'backgroundColor', 'insertTable' ], buttonsVisible: 11},
			}

			// if we're on a small screen, reconfigure for two lines
			if (vapp.$vuetify.breakpoint.xsOnly) {
				toolbarButtons = {
					moreText: {buttons: ['bold', 'italic', 'underline', 'emoticons', 'insertmathtype', 'textColor', 'backgroundColor', ], buttonsVisible: 7, align:'left'},
					moreParagraph: {buttons: ['formatUL', 'formatOL', 'align', 'insertLink', 'undo', 'redo' ], buttonsVisible: 6, align:'left'},	// , 'sparklfontsize'
					moreMisc: {buttons: ['fullscreen'], buttonsVisible: 1, align:'right'},
				}
			}

			let config = {
				// key: vapp.$store.state.froala_key,
				placeholderText: 'Enter a model response here',
				charCounterCount: false,
				attribution: false,
				quickInsertEnabled: false,
				// theme: 'dark',
				heightMin:60,
				heightMax:200,
				enter: FroalaEditor.ENTER_BR,
				
				htmlUntouched: true,	// Leave the HTML inside the editor untouched without doing any special processing to it except HTML cleaning.
				entities: '',	// this stops froala from converting unicode characters like ‘’“”'" to entities; the exceptions are [ &amp; &gt; &lt; ]
				htmlSimpleAmpersand: true,	// this stops conversion of & to &amp; -- I don't see a way to do this for <>, and we probably wouldn't want to do that anyway
				
				// we need 'span' to be listed here because otherwise, mathlive 'strut' spans are removed
				htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'span'],
				toolbarButtons: toolbarButtons,
				linkInsertButtons: ['linkBack'],
				linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
				linkAutoPrefix: 'https://',
				linkAlwaysBlank: true,

				imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
				imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
				imageRoundPercent: true,		// round to integer when resizing
				// don't allow images, at least at this time, in CR responses
				imagePaste: false,
				imageUpload: false,

				events: {
					'paste.beforeCleanup': function (clipboard_html) {
						return window.clean_froala_pasted_text(clipboard_html, 'br')
					},
					'paste.afterCleanup': function() { },
				},
				entities: '',	// this stops froala from converting unicode characters like ‘’“”'" to entities; the exceptions are [ &amp; &gt; &lt; ]
				htmlSimpleAmpersand: true,	// this stops conversion of & to &amp; -- I don't see a way to do this for <>, and we probably wouldn't want to do that anyway
				// pastePlain: true,

				// 'inlineStyle', 'insertHR', 'selectAll', 'print', 'help', 'fontFamily', 'undo', 'redo'
				events: {},
			}

			config = U.get_froala_config(config)

			return config
		},

		no_lesson_date() {
			this.edited_lesson.lesson_date = ''
			this.lesson_date_menu = false
		},

		remove_item(resource, confirmed) {
			// check to see if the resource is anywhere in the text of the lesson (unless already confirmed)
			if (!confirmed) {
				let found = 0
				let position_string
				for (let ci = 0; ci < this.lesson_plan.length; ++ci) {
					let comp = this.lesson_plan[ci]
					for (let cpi = 0; cpi < comp.lc_parts.length; ++cpi) {
						if (comp.lc_parts[cpi].lcp_teacher_content.indexOf(resource.resource_id) > -1) {
							++found
							position_string = sr('<nobr>“$1”', this.lesson_plan[ci].lc_title)
							if (this.lesson_plan[ci].lc_parts.length > 1) position_string += ', part ' + (cpi+1)
							position_string += '</nobr>'
						}
					}
				}

				if (this.edited_lesson.student_description.indexOf(resource.resource_id) > -1) {
					++found
					position_string = '“Student-Facing Lesson Overview”'
				}

				if (found) {
					let found_string = (found==1) ? '' : 'at least ' + found + ' times'

					this.$confirm({
					    title: 'Are you sure?',
					    text: sr('This resource is linked $1 from your Lesson text, in the $2 portion of the Lesson. Are you sure you want to remove the resource? If you do so, the resource link(s) will also be removed from the Lesson Component(s).', found_string, position_string),
					    acceptText: 'Remove Resource',
						acceptColor: 'red',
						dialogMaxWidth: 600
					}).then(y => {
						this.remove_item(resource, true)
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}
			}

			// remove from lesson text			
			let re = new RegExp('<link class="k-lesson-component-resource-link[^>]+' + resource.resource_id + '[^>]+>', 'g')
			for (let comp of this.lesson_plan) {
				for (let part of comp.lc_parts) {
					part.lcp_teacher_content = part.lcp_teacher_content.replace(re, '')
				}
			}
			this.edited_lesson.student_description = this.edited_lesson.student_description.replace(re, '')

			// splice the resource out
			let i = this.edited_lesson.resources.findIndex(x=>x==resource)
			if (i > -1) this.edited_lesson.resources.splice(i, 1)

			// then immediately save
			this.save_lesson()
		},

		create_resource_start() {
			// if this is initiated from the froala editor, show the user the option of inserting resources from the lesson
			if (this.fco) this.resource_search_home_lesson = this.edited_lesson

			// show the search dialog
			this.show_search = true
		},

		add_items_from_search(items) {
			// go through each item...
			for (let item of items) {
				let updated_resource = new Resource(item.value)

				// add to lesson resources, unless it's already there
				if (!this.edited_lesson.resources.find(x=>x.resource_id == updated_resource.resource_id)) {
					this.edited_lesson.resources.push(updated_resource)
				}

				// if we were adding to a lesson text, call insert_resource_selected, then close the interface on next tick
				if (this.fco) {
					this.insert_resource_selected(updated_resource)
					this.$nextTick(x=>this.search_cancel())
				}
			}

			this.save_lesson()
			this.$inform(`${items.length} ${U.ps('resource', items.length)} added`)
		},

		search_cancel() {
			// call insert_resource_cancel in case we were inserting a resource in a lesson component (note: this will not do anything if we just called insert_resource_selected
			this.insert_resource_cancel()
			this.resource_search_home_lesson = null
			this.show_search = false
		},

		// this is called from ResourceEditor, via ResourceCollectionItem
		edit_item_saved(args) {
			let updated_resource = new Resource(args.updated_resource)

			// the resource editor will have saved the resource to the db; splice it to edited_lesson.resources
			let index = this.edited_lesson.resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
			if (index == -1) {
				// shouldn't happen
				this.$alert('error: couldn’t find index of edited resource')
				return
			} else {
				this.edited_lesson.resources.splice(index, 1, updated_resource)
				// the lesson data won't change, so we have to update the original_unit too
				if (!this.is_new_lesson) this.original_lesson.resources.splice(index, 1, new Resource(updated_resource))
			}

			// save lesson edits right away whenever a new resource is updated
			this.save_lesson()
		},

		edit_item_cancel(args) {
			// we don't have to do anything here
		},

		reorder_resources() {
			this.save_lesson()
			this.resource_reorder_open = false
		},

		///////////////////////////////////
		// Standards
		align_to_standard(start_item) {
			// always show the full text of standards when we start aligning
			this.edited_lesson.standards_showing = true

			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if the lesson has a case_framework_identifier, send it
				if (this.edited_lesson.case_framework_identifier) data.framework_identifier = this.edited_lesson.case_framework_identifier

				// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// if the lesson has a course_case_identifier, send it
				if (this.edited_lesson.course_case_identifier) data.item_identifier = this.edited_lesson.course_case_identifier

				// HACK: trace up through parents looking for a course_case_identifier value (e.g. in CourseView); if found, use it as item_identifier
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current lesson standards as selected items
			if (this.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.standards) data.selected_items.push(standard.identifier)
			}

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($(vapp.lesson_editor_component?.$el).is(':visible') == false) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.standards.findIndex(o=>o.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier. use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
							let o = new CASE_Item(aligned_item.cfitem)
							o.framework_identifier = aligned_item.framework_identifier
							this.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
						
						// unless this is a new lesson, call save_lesson here (if it *is* a new lesson, don't do this here because we have to close satchel when we save a new lesson
						if (!this.is_new_lesson) {
							this.save_lesson('no_spinner')
						}
					})
				})
			})
		},

		remove_standard(s, confirmed) {
			// console.log(s)
			let i = this.standards.findIndex(o=>o.identifier==s.identifier)
			if (i > -1) {
				if (!this.confirmed_standard_delete && !confirmed) {
					this.$confirm({
						title: 'Remove Standard Alignment',
						text: 'Are you sure you want to remove this standard alignment from the Lesson Plan?',
						acceptText: 'Remove',
						acceptColor: 'red',
					}).then(y => {
						this.remove_standard(s, true)
						this.confirmed_standard_delete = true
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				this.standards.splice(i, 1)
				this.save_lesson('no_spinner')
			}
		},

		drag_complete(evt) {
			// standards will have been reordered; just need to save
			this.save_lesson('no_spinner')
		},

		///////////////////////////////////
		flash_save_indicator() {
			setTimeout(x=>{
				// flash the 'saved' message
				this.just_saved = true
				setTimeout(x=>this.just_saved = false, 2000)
			}, 10)
		},

		save_and_close() {
			this.save_lesson('and_close')
		},

		save_lesson(flag) {
			// 'no_spinner' saves are "intermediate" saves; don't do this for new lessons
			// PW: no, now we're allowing for auto-save for new lessons
			// if (flag == 'no_spinner' && this.is_new_lesson) return

			if (this.is_new_lesson) {
				// have to close satchel in this case, because this component will be re-opened with the newly-saved lesson
				vapp.$refs.satchel.execute('hide')
			}

			this.edited_lesson.lesson_title = $.trim(this.edited_lesson.lesson_title)
			if (empty(this.edited_lesson.lesson_title)) {
				// if we haven't 
				this.$alert('You must enter a title for the lesson.')
				return
			}

			let new_stringified_lesson = JSON.stringify(this.edited_lesson.copy_for_save())
			if (new_stringified_lesson == this.stringified_original_lesson) {
				console.warn('NOT SAVED because no change')
				// if flag is 'and_close', close lesson editor
				if (flag == 'and_close') {
					this.edit_lesson_cancel()
				} else {
					this.flash_save_indicator
				}
				return
			}

			if (flag != 'no_spinner') U.loading_start()
			U.ajax('save_lesson', {
				user_id: this.user_info.user_id,
				lesson_class: this.lesson_class,
				lesson_data: new_stringified_lesson
			}, result=>{
				if (flag != 'no_spinner') U.loading_stop()
				this.flash_save_indicator()
				if (result.status != 'ok') {
					console.log('Error in ajax call'); vapp.ping(); return;
				}

				console.warn('SAVED')

				// updated data will be returned; emit it to the parent component, along with the flag
				// (the flag might be 'and_close', in which case the parent should take care of closing the editor)
				this.$emit('edit_lesson_saved', {updated_lesson: result.updated_lesson, flag:flag, edited_lesson:this.edited_lesson})	// pass edited_lesson so we can preserve *_showing values

				// save new stringified original lesson
				this.stringified_original_lesson = new_stringified_lesson
			});
		},

		edit_lesson_cancel(confirmed) {
			if (confirmed !== true && this.stringified_original_lesson != JSON.stringify(this.edited_lesson.copy_for_save())) {
				this.$confirm({
				    text: 'You appear to have made changes. Are you sure you want to close the lesson editor with out saving these changes?',
					acceptText: 'Don’t Save',
				}).then(y => {
					U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.edited_lesson.lesson_id})
					this.edit_lesson_cancel(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}
			U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.edited_lesson.lesson_id})
			this.$emit('edit_lesson_cancel')
		},


		//////////////////////////////
		stage_suggestible(component) {
			// if we don't have master data for the component, we can't make suggestions
			let mdc = this.master_component(component)
			if (!mdc) return false

			// if we have standards, we can make suggestions based on the standards alone
			if (this.standards.length > 0) return true

			// if any of the stages listed in this component's lc_suggestible_criteria has content, we can make suggestions based on that content
			for (let stage of mdc.lc_suggestible_criteria) {
				let c = this.lesson_plan.find(x=>x.lc_uuid == stage)
				if (!empty(c?.lc_content)) return true
			}

			// if we get to here, we can't make suggestions
			return false
		},

		stage_status(component) {
			if (component == 'title') return !empty(this.edited_lesson.lesson_title) ? 'complete' : ''
			else if (component == 'standards') return this.standards.length > 0 ? 'complete' : ''
			else if (component == 'resources') return this.edited_lesson.resources.length > 0 ? 'complete' : ''
			else if (component == 'sparkl') return (this.edited_lesson.sparkl_activity_id != 0) ? 'complete' : ''
			else return component.lc_status
		},

		section_stage_css(component) {
			let status = this.stage_status(component)
			let mdc = this.master_component(component)
			let s = `k-lpe-alba-content-section-${component.lc_uuid}`
			if (status == 'complete') s += ' k-lpe-alba-section-stage-complete'
			if (component?.lc_open_for_editing) s += ` ${mdc?.lc_color} lighten-5 k-lpe-alba-section-stage-open_for_editing`
			return s
		},

		menu_stage_css(component) {
			let status = this.stage_status(component)
			let s = ''
			if (status == 'complete') s += ' k-lpe-alba-menu-stage-complete'
			if (component?.lc_open_for_editing) s += ' k-lpe-alba-menu-stage-open_for_editing'
			return s
		},

		menu_stage_icon(component) {
			let status = this.stage_status(component)
			if (status == 'complete') return 'fas fa-circle-check'
			if (status == 'skipped') return 'fas fa-circle-minus'
			else return 'far fa-circle'
		},

		section_text(component) {
			let content = U.render_latex(component.lc_content)
			let mdc = this.master_component(component)
			if (empty(mdc)) return content

			if (empty(mdc?.lc_text_format) || mdc.lc_text_format == 'paragraphs') {
				// for 'paragraphs' style, or if we don't have a master component or lc_text_format is empty, just return the plain content
				return content

			} else if (mdc.lc_text_format == 'line') {
				// for single-line style (e.g. questions), wrap in a paragraph
				return `<p>${content}</p>`

			} else {
				// else assume it's a list (could be, e.g. 'list' or 'list_plain'; use unordered list
				let lines = content.split('<br>')
				let s = `<ul>`
				for (let line of lines) s += `<li>${line}</li>`
				s += `</ul>`
				return s
			}
		},

		component_suggestion_html(component, suggestion) {
			return suggestion
			// we want to format suggestions differently depending on aspects of the component
		},

		open_next_component_for_editing() {
			// if something is already open for editing, just return
			for (let c of this.lesson_plan) {
				if (c.lc_open_for_editing) return
			}

			// if not, open the first non-skipped, non-complete stage for editing
			for (let c of this.lesson_plan) {
				if (empty(c.lc_status)) {
					c.lc_open_for_editing = true
					return
				}
			}
		},

		stage_done_clicked(component) {
			if (component == 'standards') {
				// when user clicks done for standards, open something for editing if needed
				this.open_next_component_for_editing()
				// hide satchel
				vapp.$refs.satchel.execute('hide')
				// set standards_done_clicked to true so we don't show the "DONE" btn anymore
				this.standards_done_clicked = true

				// auto-save
				this.save_lesson('no_spinner')

				return
			}

			this.trim_content()
			// if we have content and the user clicked this btn, status is complete
			if (!empty(component.lc_content)) component.lc_status = 'complete'
			// otherwise status is skipped
			else component.lc_status = 'skipped'
			// either way, lc_open_for_editing is false
			component.lc_open_for_editing = false

			// set the first not-completed, not-skipped stage as open_for_editing
			let got_to_current = false
			for (let c of this.lesson_plan) {
				if (c == component) {
					got_to_current = true
					continue
				}
				if (!got_to_current) continue

				if (empty(c.lc_status)) {
					c.lc_open_for_editing = true
					break
				}
			}

			// auto-save
			this.save_lesson('no_spinner')
		},

		toggle_stage(component) {
			// if the stage isn't already open, open it and scroll to stage
			if (!component.lc_open_for_editing) {
				component.lc_open_for_editing = true
				this.scroll_to_stage(component)
			
			// else do the same thing we would do if the user clicked the 'skip' or 'done' btn
			} else {
				this.stage_done_clicked(component)
			}
		},

		go_to_stage(component) {
			// this fn is called from the menu only. when called, go through all the stages...
			let this_stage_reached = false
			for (let s of this.lesson_plan) {
				// for the clicked stage, we toggle whether it is open for editing
				if (s == component) {
					component.lc_open_for_editing = !component.lc_open_for_editing
					this_stage_reached = true
				
				// for stages previous to the clicked stage,
				} else if (!this_stage_reached) {
					// if the stage is empty, close it (but don't mark as skipped)
					if (empty(s.lc_content)) {
						s.lc_open_for_editing = false
					}

				// for stages after the clicked stage,
				} else {
					// also close editors for empty items
					if (empty(s.lc_content)) {
						s.lc_open_for_editing = false
					}
				}
			}

			if (component == 'standards') this.edited_lesson.standards_showing = !this.edited_lesson.standards_showing
			if (component == 'resources') this.edited_lesson.resources_showing = !this.edited_lesson.resources_showing
			if (component == 'sparkl') this.edited_lesson.sparkl_showing = !this.edited_lesson.sparkl_showing

			this.scroll_to_stage(component)
		},

		scroll_to_stage(component) {
			// scroll to stage; note that target selector logic handles whether component is an actual component or a stage name (e.g. 'title')
			setTimeout(x=>{
				let target = $(this.$el).find(`.k-lpe-alba-content-section-${component?.lc_uuid ?? component}`)[0]
				if (!empty(target)) {
					let container = $(this.$el).find('.k-lpe-alba-content-inner')[0]
					// let container = $('.v-dialog').find('.v-card__text')[0]
					this.$vuetify.goTo(target, {container:container, offset:0})
				}
			}, 100)
		},

		remove_suggestion(component, index) {
			component.lc_suggestions.splice(index, 1)
			// if first or last array item is '---', remove
			if (component.lc_suggestions[0] == '---') component.lc_suggestions.splice(0, 1)
			if (component.lc_suggestions[component.lc_suggestions.length-1] == '---') component.lc_suggestions.splice(component.lc_suggestions.length-1, 1)
		},

		clear_suggestions(component) {
			component.lc_suggestions = []
		},

		add_suggestion(component, index) {
			let s = component.lc_suggestions[index]
			this.remove_suggestion(component, index)
			let mdc = this.master_component(component)

			// for lc_text_format 'list' (or 'list_plain'), add a <br> if not currently empty, then add the suggestion
			if (empty(mdc) || mdc.lc_text_format.includes('list')) {
				if (!empty(component.lc_content)) component.lc_content += '<br>'
				component.lc_content += s
			
			// for 'paragraphs' format, add suggestion as a paragraph
			} else if (mdc.lc_text_format == 'paragraphs') {
				component.lc_content += s
				// component.lc_content += `<p>${s}</p>`
			
			// else ('line' format), add a space if not currently empty, then add the suggestion
			} else {
				if (!empty(component.lc_content)) component.lc_content += ' '
				component.lc_content += s
			}

			// update the froala value (note the [0])
			this.$refs['froala_' + component.lc_uuid][0].set_value(component.lc_content)

			// add to lesson gpt stats
			this.edited_lesson.gpt_suggestion_accepts += 1

			this.save_lesson('no_spinner')
		},

		remove_model_suggestion(component, index) {
			component.lc_model_suggestions.splice(index, 1)
		},

		add_model_suggestion(component, index) {
			let s = component.lc_model_suggestions[index]
			this.remove_model_suggestion(component, index)
			// note that here we replace any previously-entered model response with the selected suggestion
			component.lc_sparkl_model_response = s

			// update the froala value (note the [0])
			this.$refs['froala_model_response_' + component.lc_uuid][0].set_value(component.lc_sparkl_model_response)

			// add to lesson gpt stats
			this.edited_lesson.gpt_suggestion_accepts += 1

			this.save_lesson('no_spinner')
		},

		trim_content() {
			for (let component of this.lesson_plan) {
				let s = component.lc_content
				if (empty(s)) continue

				// console.log(`before trim: “${s}”`)
				s = s.replace(/\s*(<\/p>)/g, '$1')		// trim spaces before the ends of paragraphs
				s = s.replace(/(<p>)\s*/g, '$1')		// trim spaces at the starts of paragraphs
				s = s.replace(/<p>\s*<\/p>/g, '')		// remove empty paragraphs

				s = s.replace(/\s*(<br>)/g, '$1')		// trim spaces before line breaks
				s = s.replace(/(<br>)\s*/g, '$1')		// trim spaces after line breaks
				s = s.replace(/(<br>)+/g, '<br>')		// reduce two or more line breaks to a single line break
				s = s.replace(/<br>+$/g, '')			// remove line breaks at the end of the content

				s = $.trim(s)

				component.lc_content = s
				// console.warn(`after trim: “${s}”`)
			}
		},

		message_text(base, sub) {
			let s = base.replace(/\$format_for_gpt_message/g, sub)
			s = s.replace(/\$suggestions_to_request/g, this.suggestions_to_request)
			s = s.replace(/<(p|div|span|li).*?>/g, '')
			s = s.replace(/<\/(p|div|span|li).*?>/g, ' ')
			return U.html_to_text(s)
		},

		sparkl_include_help_text(s) {
			s = s.replace(/\$sparkl_app_name\b/g, this.site_config.sparkl_app_name)
			return s
		},

		make_response_suggestion(component) {
			// if we don't have a master component for this component, we can't make suggestions (this might happen if the lesson was imported from another system...)
			let mdc = this.master_component(component)
			if (!mdc || empty(mdc.lc_gpt_model_prompt)) {
				this.$alert('Model response suggestions cannot be made for this lesson component.')
				return false
			}

			// trim all content; then if this stage doesn't have content, return (this shouldn't happen)
			this.trim_content()
			if (empty(component.lc_content)) return

			// system: You are a laconic assistant. You reply with brief, to-the-point answers. You do not repeat user questions in your answers.
			// system: You are a helpful assistant that does not repeat user questions in your answers.

			// message is lc_gpt_model_prompt with substitutions; start with standard message_text substitutions
			let messages = this.message_text(mdc.lc_gpt_model_prompt, component.lc_content)

			// compose grade_phrase based on standards if we have them
			let grade_phrase = ''
			let grade_low = 100
			let grade_high = -10
			if (this.standards.length > 0) {
				for (let i = 0; i < this.standards.length; ++i) {
					let s = this.standards[i]
					if (U.grade_value(s.grade_low) < grade_low) grade_low = U.grade_value(s.grade_low)
					if (U.grade_value(s.grade_high) > grade_high) grade_high = U.grade_value(s.grade_high)
				}
			}
			grade_low = vapp.$store.state.grades[grade_low]		// todo: spell out "pre-kindergarten" and "kindergarten"
			grade_high = vapp.$store.state.grades[grade_high]
			if (grade_low || grade_high) {
				if (grade_low == grade_high) grade_phrase = 'in Grade ' + grade_low
				else grade_phrase = `in Grades ${grade_low} to ${grade_high}`
			}
			// replace grade_phrase (this will be an empty string if we don't have standards)
			messages = messages.replace(/\$grade_phrase/, grade_phrase)

			// console.log(messages)
			// return

			// call the gpt function, then add resulting suggestions to the component's lc_model_suggestions
			this.gpt_start({messages: messages}).then(responses=>{
				console.log(responses)
				// component.lc_sparkl_model_response = responses[0]
				// // update the froala value (note the [0])
				// this.$refs['froala_' + component.lc_uuid][0].set_value(component.lc_content)
				component.lc_model_suggestions.push(responses[0])

				// save, so that we have the suggestions if the user leaves and comes back later
				this.save_lesson('no_spinner')
			})
		},

		make_component_suggestions(component) {
			// if we don't have a master component for this component, we can't make suggestions (this might happen if the lesson was imported from another system...)
			let mdc = this.master_component(component)
			if (!mdc) {
				this.$alert('Suggestions cannot be made for this lesson component.')
				return false
			}

			// trim all content; then if this stage isn't actually suggestible (i.e. because we don't have anything to base suggestions on), show an appropriate alert
			this.trim_content()
			if (!this.stage_suggestible(component)) {
				this.$alert(mdc.lc_not_suggestible_msg)
				return
			}

			if (!this.alba_warning_issued) {
				this.$alert({
					title: '<i class="fas fa-robot pink--text text--darken-4 mr-2"></i>About Lesson Suggestions',
					text: '<p>Lesson suggestions are generated by a large language model (LLM), commonly called an artificial intelligence (AI) system, on the basis of the standards you’ve chosen and other information you’ve already specified in your lesson plan.</p><p>Many teachers find these suggestions extremely helpful as they develop their lesson plans. However, please keep in mind that LLMs are <i>not</i> actually “intelligent”, so you should expect these suggestions to vary in quality.</p><p class="mt-3 text-center pink--text text--darken-4" style="font-size:20px;line-height:26px">Remember: <b><i>YOU</i></b> must be the final arbiter <nobr>of what you decide to teach your students!</nobr></p>',
					acceptText: ' Got It!',
					acceptIconAfter: 'fas fa-arrow-right',
					dialogMaxWidth: 620,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.alba_warning_issued = true
					this.make_component_suggestions(component)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// system: You are a laconic assistant. You reply with brief, to-the-point answers. You do not repeat user questions in your answers.
			// system: You are a helpful assistant that does not repeat user questions in your answers.

			// base message
			let messages = `user: I am a teacher. Can you help me write a lesson plan?`

			// start with messages for standards and grades if we have them
			if (this.standards.length > 0) {
				// compose phrases about the standards and grades being taught to send to gpt
				let grade_phrase = '', standards_phrase = ''
				let grade_low = 100
				let grade_high = -10
				for (let i = 0; i < this.standards.length; ++i) {
					let s = this.standards[i]

					if (i > 0) standards_phrase += ' and '
					standards_phrase += `"${U.html_to_text(s.fullStatement)}"`

					if (U.grade_value(s.grade_low) < grade_low) grade_low = U.grade_value(s.grade_low)
					if (U.grade_value(s.grade_high) > grade_high) grade_high = U.grade_value(s.grade_high)
				}

				grade_low = vapp.$store.state.grades[grade_low]		// todo: spell out "pre-kindergarten" and "kindergarten"
				grade_high = vapp.$store.state.grades[grade_high]
				if (grade_low || grade_high) {
					if (grade_low == grade_high) grade_phrase = 'Grade ' + grade_low
					else grade_phrase = `Grades ${grade_low} to ${grade_high}`
				}

				messages += `\nassistant: Yes. What learning standards are covered in the lesson?`
				messages += `\nuser: ${standards_phrase}`

				if (grade_phrase) {
					messages += `\nassistant: What grade level are your students?`
					messages += `\nuser: ${grade_phrase}`
				}
			}

			// now add messages about components other than the current component that we've already filled in
			// for each component in the master lesson plan...
			for (let mc of this.lesson_master.lesson_plan) {
				// skip the current component
				if (mc.lc_uuid == component.lc_uuid) continue

				// if this master component has a lc_gpt_messages_start value...
				if (!empty(mc.lc_gpt_messages_start)) {
					// then if we have content for this component, add the lc_gpt_messages_start value
					let c = this.lesson_plan.find(x=>x.lc_uuid==mc.lc_uuid)
					if (!empty(c?.lc_content)) {
						messages += this.message_text(mc.lc_gpt_messages_start, c.lc_content)
					}
				}
			}

			// if we already have content for this stage and we have lc_gpt_messages_prompt_alt, use lc_gpt_messages_prompt_alt
			if (!empty(component.lc_content) && !empty(mdc.lc_gpt_messages_prompt_alt)) {
				messages += this.message_text(mdc.lc_gpt_messages_prompt_alt, component.lc_content)
			
			// else use lc_gpt_messages_prompt
			} else {
				messages += this.message_text(mdc.lc_gpt_messages_prompt, component.lc_content)
			}

			// console.log(messages)
			// return

			// call the gpt function, then add resulting suggestions to the component
			this.gpt_start({messages: messages}).then(responses=>{
				// remember this response for debugging purposes (see remake_component_suggestions)
				if (true) {
					if (empty(vapp.gpt_responses)) vapp.gpt_responses = []
					vapp.gpt_responses.unshift({lc_uuid: component.lc_uuid, response: responses[0]})
					if (vapp.gpt_responses.length > 20) vapp.gpt_responses.pop
				}

				component.lc_suggestions.push('---')
				component.lc_suggestions = component.lc_suggestions.concat(this.parse_gpt_response_list(component, responses[0]))

				// save, so that we have the suggestions if the user leaves and comes back later
				this.save_lesson('no_spinner')
			})
		},

		gpt_start(params) {
			return new Promise((resolve, reject) => {
				if (empty(params.resolve)) {
					params.resolve = resolve
					params.reject = reject
				}
				if (this.edit_llm_suggestion_prompts && !params.prompt_approved) {
					this.$prompt({
						title: 'Suggestion Prompt',
						text: '<p>Edit the prompt to be sent to the large language model if you wish:</p>',
						promptType: 'textarea',		// default is 'text'
						initialValue: params.messages,
						// initialValue: params.messages.replace(/\n/g, '\n\n'),
						disableForEmptyValue: true,
						acceptText: 'Send Prompt',
						acceptIconAfter: 'fas fa-circle-arrow-right',
						dialogMaxWidth: 860,
					}).then(messages => {
						messages = $.trim(messages.replace(/\n+/g, '\n'))
						if (empty(messages)) return
						params.messages = messages
						if (messages.length > 2500) {
							this.$alert('Your prompt can be no longer than 2500 characters.').then(x=>this.gpt_start(params))
							return
						}
						params.prompt_approved = true
						this.gpt_finish(params)
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				
				// if we're not prompting for changes, just return
				} else {
					this.gpt_finish(params)
				}
			})
		},

		gpt_finish(params) {
			console.log(params.messages)
			// return

			let payload = {
				user_id: this.user_info.user_id,
				n: params.n ?? 1,
				model: this.gpt_model,
				temperature: this.gpt_temperature,
				frequency_penalty: this.gpt_frequency_penalty,
				seed: U.random_int(1000000),
				messages: []
			}
			if (params.model) payload.model = params.model
			if (params.temperature) payload.temperature = params.temperature
			if (params.frequency_penalty) payload.frequency_penalty = params.frequency_penalty
			if (params.messages) {
				if (typeof(params.messages) == 'string') {
					// parse lines from prompt string into messages
					let lines = params.messages.split('\n')
					for (let line of lines) {
						line = $.trim(line)
						if (empty(line)) continue
						let role, content
						if (line.search(/(user|assistant|system)\s*:\s*(.*)/) > -1) {
							role = RegExp.$1
							content = RegExp.$2
						} else {
							role = 'user'
							content = line
						}
						payload.messages.push({role: role, content: content})	
					}

				} else {
					payload.messages = params.messages
				}

			} else {
				// if we don't get messages, we should at least get content_user, and might also get content_system
				if (params.content_user) payload.messages.push({role: 'user', content:params.content_user})
				if (params.content_system) payload.messages.push({role: 'system', content:params.content_system})
			}

			// console.log('================================ gpt messages', payload)
			// for (let message of payload.messages) console.log(`${message.role}: ${message.content}`)

			U.loading_start('<i class="fas fa-robot white--text"></i> Asking LLM for suggestions')
			U.ajax('openai_query', payload, result=>{
				U.loading_stop()
				console.log('gpt output', result)
				if (result.status != 'ok') {
					this.$alert('Error querying LLM')
					params.reject()
					return
				}

				// add to GPT activity stats
				this.edited_lesson.gpt_suggestion_requests += 1
				this.edited_lesson.gpt_input_tokens += result.full_rv[0].usage.prompt_tokens
				this.edited_lesson.gpt_output_tokens += result.full_rv[0].usage.completion_tokens

				params.resolve(result.responses)
			})
		},

		test_service() {
			let payload = {messages: $.trim(this.prompt), prompt_approved: true}
			if (this.test_prompt_n * 1 > 0) payload.n = this.test_prompt_n * 1
			this.gpt_start(payload).then(responses=>{ this.prompt_responses = responses })
		},

		format_gpt_output(output) {
			// output could be a string or an array of strings
			if (typeof(output) == 'string') output = [output]

			let r = ''
			for (let s of output) {
				s = s.replace(/\n/g, '<br>')
				r += `<div style="border-bottom:1px solid #ccc" class="mb-1 pb-1">${s}</div>`
			}

			return r
		},

		parse_gpt_response_list(component, response) {
			let arr = []
			let last_item_index = -1
			let mdc = this.master_component(component)

			let lines = response.split('\n')
			for (let line of lines) {
				if (true) console.log('pgrl: ' + line)
				line = $.trim(line)

				// skip blank lines, but when we get a blank line, assume the last item is done
				if (empty(line)) {
					last_item_index = -1
					continue
				}
				// if we're here this isn't a blank line

				// assume gpt always starts each actual list item with a number, possibly preceded by a markdown header (e.g. '###')
				if (line.search(/^[# ]*(\d+)\.\s+(.*)/) > -1) {
					let num = RegExp.$1
					line = RegExp.$2

					// if this line's num is 1 and we already started the list, skip the rest of the lines -- GPT will sometimes, e.g., first send us the list we want, but then give us another list of things we don't want
					if (num == '1' && arr.length > 0) {
						console.log(`parse_gpt_response_list: skipping secondary list of items starting with “${line}”`)
						break
					}

					// for lc_text_format 'line' (used for questions), strip opening bold text (e.g. "**Observation Question**: ")
					if (mdc.lc_text_format == 'line') {
						line = line.replace(/^\*\*.*?\*\*:\s+/, '')
						line = line.replace(/^\*\*.*?:\*\*\s+/, '')
					}

					// if entire line is bolded, de-bold (ditto italics)
					line = line.replace(/^\*+(.*?)\*+$/, '$1')

					// if line is quoted, get rid of the quotes
					line = line.replace(/^["“](.*?)["”]$/, '$1')

					last_item_index = arr.length
					arr.push(line)

				// // if the line starts with -\s+, assume it's a list item for the previous suggestion
				// } else if (line.search(/^\s*-\s+(.*)/) > -1) {
				// 	line = RegExp.$1
				// 	// if last_item_index is -1, error
				// 	if (last_item_index == -1) {
				// 		console.log(`parse_gpt_response_list: unordered list line found with no last_item_index: “${line}”`)
				// 		continue
				// 	}

					// if we've started an item and there hasn't been a blank line after that item, assume it's a list item for the previous suggestion
				} else if (last_item_index > -1) {
					// remove possible list item markdown
					line = line.replace(/^\s*-\s+(.*)/, '$1')

					// start an unordered list for the previous item if we didn't already start one
					if (!arr[last_item_index].includes('<ul>')) {
						arr[last_item_index] += '<ul>'
					}

					// then add the line as a list item
					arr[last_item_index] += `<li>${line}</li>`

				} else {
					console.log(`parse_gpt_response_list: unparsed line “${line}”`)
				}
			}
			// done parsing all lines

			// now go back through each item...
			for (let i = 0; i < arr.length; ++i) {
				// if any items have a <ul> with only one <li>, remove the list, and make sure we have a colon between the term and the definition
				arr[i] = arr[i].replace(/<ul><li>([^<]*?)<\/li>$/, ': $1')
				arr[i] = arr[i].replace(/::/, ':')

				// for items that still have <ul>'s, close the lists
				if (arr[i].includes('<ul>')) arr[i] += '</ul>'

				// if lc_text_format includes 'plain', strip markdown out
				if (mdc && mdc.lc_text_format.includes('plain')) {
					arr[i] = arr[i].replace(/\*\*(\w.*?)\*\*/g, '$1')
					arr[i] = arr[i].replace(/\*(\w.*?)\*/g, '$1')
				
				// by default process some markdown
				} else {
					arr[i] = arr[i].replace(/\*\*(\w.*?)\*\*/g, '<b>$1</b>')
					arr[i] = arr[i].replace(/\*(\w.*?)\*/g, '<i>$1</i>')
				}
			}

			return arr
		},

		// vapp.lesson_editor_component.remake_component_suggestions(0)
		// vapp.lesson_editor_component.remake_component_suggestions(0, 'parse_only')
		remake_component_suggestions(index, flag) {
			if (empty(index)) index = 0
			let ro = vapp.gpt_responses[index]
			if (empty(ro)) {
				this.$alert('no response object for index ' + index)
				return
			}

			let component = this.edited_lesson.lesson_plan.find(x=>x.lc_uuid == ro.lc_uuid)
			if (empty(component)) {
				this.$alert('couldn’t find component for ' + ro.lc_uuid)
				return
			}

			let rv = this.parse_gpt_response_list(component, ro.response)
			console.log(rv)

			if (flag == 'parse_only') return
			component.lc_suggestions.push('---')
			component.lc_suggestions = component.lc_suggestions.concat(rv)
		},

		////////////////////////////////////////////
		open_sparkl_activity() {
			if (!this.sparkl_activity_created) return

			vapp.$refs.sparkl_embed.show_activity({
				activity_record: {
					tool_activity_id: this.edited_lesson.sparkl_activity_id,
					lti_resource_link_id: 'sparkl_for_lesson_' + this.edited_lesson.lesson_id,
					activity_title: this.edited_lesson.lesson_title,
					creator_user_id: this.user_info.user_id,
				},
				force_reload: this.force_reload_sparkl,
				embed_mode: 'view',

				// the following two probably aren't really needed
				force_allow_original_to_be_edited: 'yes',
				force_prevent_original_to_be_edited: 'no',

				show_copy_for_my_use_btn: false,
				viewing_original_of_in_my_collections: false,
				controller_component: this,

				sparkl_origin_override: this.sparkl_origin_override,	// could be null
			})

			this.force_reload_sparkl = false

			// hide the edit lesson dialog while the activity shows
			$('.v-dialog__content--active, .v-overlay--active').hide()
		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			console.log(`sparkl_activity_saved in LessonEditorEnhanced`, activity_data_from_sparkl)
			// this is called when Sparkl issues a 'sparkl_activity_saved' message
			// activity_data_from_sparkl should include sparkl_activity_id, stars_available, activity_instructions, activity_title, activity_editors
			// currently we're not doing much here; see other instantiations of sparkl_activity_saved for other things we might want to do

			// if sparkl_closed_from_embed is true, finish closing sparkl here
			if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
		},

		close_sparkl() {
			// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
			console.log(`close_sparkl in LessonEditorEnhanced; host_activity_saved being called`)
			U.loading_start()
			vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

			this.sparkl_closed_from_embed = true
			// ... then once sparkl is done saving, sparkl_activity_saved will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish
		},

		close_sparkl_finish() {
			U.loading_stop()
			this.sparkl_closed_from_embed = false
			vapp.$refs.sparkl_embed.hide_activity()

			// re-show active dialogs/overlays
			$('.v-dialog__content--active, .v-overlay--active').show()
		},

		create_sparkl_activity() {
			/////////////////////////
			// establish the base activity data
			let activity_data = {
				title: this.edited_lesson.lesson_title,
				editors: `,${this.user_info.email},`,
				activity_instructions: '',
				exercises: [],
				// set completion_mode to off
				completion_mode: 'no',
				// set allow_retry to on
				allow_retry: 'yes',
				// set 'open-door' policy to off
			    student_access_policy: 'signed_in',	// 'no_sign_in'
				// display settings
				default_activity_display_settings: {
					sound_effects: 'subtle',	// 'on', 'off'
					colors: 'more',	// 'less'
					sparkl_bot: 'on',	// 'off'
				},
				stars_available: 0,

				case_alignments: [],
			}
			// copy standards alignments using sparkl format
			for (let s of this.standards) {
				activity_data.case_alignments.push(s.convert_to_sparkl_format())
			}

			let exercises_data = []

			/////////////////////////
			// add an exercise (or student instructions) for each included lesson component
			for (let component of this.lesson_plan) {
				if (!component.lc_include_in_sparkl) continue

				let mdc = this.master_component(component)
				if (!mdc) continue

				let exercise_data = {
					exercise_id: component.lc_sparkl_exercise_id ? component.lc_sparkl_exercise_id : U.new_uuid(),
					exercise_title: mdc.lc_title,
					exercise_type: 'freeform',
					body: '',
					parts: [],
					queries: {},
					stars_available: 0,
				}

				// create sparkl exercise template based on lc_content and lc_sparkl_include_method

				// ============== STUDENT INSTRUCTIONS
				if (mdc.lc_sparkl_include_method == 'student_instructions') {
					activity_data.activity_instructions += `${mdc.lc_title_plural}:`
					activity_data.activity_instructions += this.section_text(component)
					
				// ============== BASIC FREEFORM EXERCISE
				} else if (mdc.lc_sparkl_include_method == 'basic_content') {
					let bc_query = new Basic_Content_Query({
						uuid: component.lc_sparkl_query_ids[0],	// this could be empty
						part_index: 0,
						stars_available: 1,
						permanent: false,
					})
					component.lc_sparkl_query_ids[0] = bc_query.uuid	// save query uuid so we re-use the same one later
					exercise_data.queries[bc_query.uuid] = bc_query
					exercise_data.body = this.section_text(component)
					exercise_data.parts.push({
						part_type: 'basic_content',
						interactive_reading: 'off',
					})
					exercise_data.stars_available = 1

				// ============== IR EXERCISE
				} else if (mdc.lc_sparkl_include_method == 'interactive_reading') {
					// create the ir query
					let ir_query = new Interactive_Reading_Query({
						uuid: component.lc_sparkl_query_ids[0],	// this could be empty
						part_index: 0,
					})
					component.lc_sparkl_query_ids[0] = ir_query.uuid	// save query uuid so we re-use the same one later
					exercise_data.queries[ir_query.uuid] = ir_query

					// calculate number of words for auto-blanks
					let num_blanks = 0
					let blankable_words = U.auto_blank_process_html(component.lc_content)
					if (blankable_words.length > 0) {
						num_blanks = U.auto_blank_num_blanks(blankable_words.length, 'fewer')
					}

					// create one query per auto-blank
					for (let i = 0; i < num_blanks; ++i) {
						let query = new window.Hangman_Query({
							uuid: component.lc_sparkl_query_ids[i+1],	// this could be empty
							blank_letters: 1,
							stars_per_letter: 1,
							part_index: 0,
							correct_answer:'AUTO',
							auto_blank: true,
							stars_available: 1,
						})
						component.lc_sparkl_query_ids[i+1] = query.uuid	// save query uuid so we re-use the same one later
						exercise_data.queries[query.uuid] = query
					}

					exercise_data.body = this.section_text(component)
					exercise_data.parts.push({
						part_type: 'interactive_reading',
						interactive_reading: 'on',
						auto_blanks: 'on',
						ab_count: 'fewer',
						ab_letters: '1',
					})
					exercise_data.stars_available = num_blanks
				
				// ============== CR EXERCISE
				} else if (mdc.lc_sparkl_include_method == 'cr_query') {
					let cr_query = new CR_Query({
						uuid: component.lc_sparkl_query_ids[0],	// this could be empty
						part_index: 0,
						prompt: component.lc_content,
						model_response: component.lc_sparkl_model_response,
						answer_type: 'text',
						stars_available: 8,
					})
					component.lc_sparkl_query_ids[0] = cr_query.uuid	// save query uuid so we re-use the same one later
					exercise_data.queries[cr_query.uuid] = cr_query
					exercise_data.body += `<p>[[${cr_query.uuid}]]</p>`
					exercise_data.parts.push({
						part_type: 'cr',
						interactive_reading: 'off',
					})
					exercise_data.stars_available = 8

				// ============== FLASHCARDS EXERCISE
				} else if (mdc.lc_sparkl_include_method == 'flashcards') {
					exercise_data.exercise_type = 'flashcards'
					exercise_data.required_mode = 'browse'

					let lines = component.lc_content.split('<br>')
					for (let i = 0; i < lines.length; ++i) {
						let arr = lines[i].match(/^\s*(.*)\s*[:-]\s*(.*)\s*$/)
						if (!arr) {
							console.warn('bad flashcard line: ' + lines[i])
							continue
						}
						let query = new Flashcard_Query({
							uuid: component.lc_sparkl_query_ids[i],	// this could be empty
							part_index: -1,
							stars_available: 1,
							term: arr[1],
							definition: arr[2],
						}, {required_mode: 'browse'})
						component.lc_sparkl_query_ids[0] = query.uuid	// save query uuid so we re-use the same one later
						exercise_data.queries[query.uuid] = query
						exercise_data.stars_available += 1
					}
				}

				// Add exercise to activity if we created one
				if (mdc.lc_sparkl_include_method != 'student_instructions') {
					exercises_data.push(exercise_data)
					activity_data.exercises.push({exercise_id: exercise_data.exercise_id})
					activity_data.stars_available += exercise_data.stars_available

					// also save exercise_id to component
					component.lc_sparkl_exercise_id = exercise_data.exercise_id
				}
			}

			/////////////////////////
			// save, or update, the activity

			// if we already have an activity_id, send it through too, so we update the existing activity
			if (this.edited_lesson.sparkl_activity_id != 0) activity_data.activity_id = this.edited_lesson.sparkl_activity_id

			let sparkl_activities = [{activity_data: activity_data, exercises_data: exercises_data}]
			console.log(`saving activity “${activity_data.title}”`, sparkl_activities)
			// return
			
			let payload = {
				user_id: this.user_info.user_id, 
				sparkl_activities: JSON.stringify(sparkl_activities),
				editor_email: this.user_info.email,
				update_or_overwrite: 'update',
			}
			if (this.sparkl_origin_override) payload.sparkl_origin_override = this.sparkl_origin_override

			U.loading_start()
			U.ajax('bulk_create_sparkl_activity', payload, result=>{
				U.loading_stop()
				console.log('bulk_create_sparkl_activity', result)

				if ((result?.status ?? '') != 'ok') {
					this.$alert('Error creating or updating activity.')
				} else {
					// let msg = (this.edited_lesson.sparkl_activity_id == 0) ? 'Activity created' : 'Activity updated'
					// this.$inform(msg)
					this.flash_save_indicator()

					// we should get the activity_id in the result as follows:
					this.edited_lesson.sparkl_activity_id = result.sparkl_rv.activity_ids[0]

					// TODO: get exercise_id's

					this.save_lesson('no_spinner')

					this.force_reload_sparkl = true

					// TODO: also bring activity in to cureum
				}
			})
		},

		//////////////////////////////////////////////////////////////
		give_feedback() {
			let issue_params = {
				item: this.edited_lesson,
			}
			vapp.report_issue(issue_params)
		},
	}
}
</script>

<style lang="scss">
// this class, or something like it, should be applied on the div that includes the LessonEditor component
.k-lpe-lesson-edit-outer {
	position:relative;
	// margin-top:8px;
	// padding:16px 8px;
	// background-color:#eee;
	// border-radius:8px;
}

.k-lpe-lesson-edit {
	.v-input {
		font-size:15px;
	}

	.k-lp-editor-standard-resource {
		padding:5px;
		margin:10px 0;
		border-radius:4px;
		background-color:#eee;
	}

	.k-lp-editor-standard-description {
		flex:1 1 auto;
		cursor:pointer;
		font-size:14px;
		line-height:18px;
	}
}

.k-lpe-lesson-editor-buttons {
	// border-top:1px solid #999;
	background-color:#eee;
	position:absolute;
	left:0;
	bottom:0px;
	border-radius:0 0 8px 8px;
	width:100%;
	display:flex;
	align-items:center;
	z-index:1001;
}

.v-dialog .k-lpe-lesson-editor-buttons {
	background-color:#fff;
	margin-bottom:-8px;
	padding-bottom:8px;
}

// this fixes things so that the "full screen" button shows up properly, at least in the lesson editor
.fr-toolbar .fr-expanded {
	width:calc(100% + 1px)!important;
	margin-left:-1px;
}

.k-lpe-editor-dialog {
	max-height:calc(100vh - 20px)!important;
}

///////////////////////////////////////////////////////////////
.k-lpe-alba-outer-outer {
	display:flex;
	// max-height:560px;
	// margin-top:8px;
	// max-height:calc(100vh - 116px);	// this works with .k-lpe-editor-dialog
	max-height:calc(100vh - 124px);	// this works with .k-lpe-editor-dialog
	border:1px solid #999;
	// border-radius:10px 0 0 10px;
}

.k-lpe-alba-menu-stages {
	flex: 0 0 268px;
	background-color:#ccc;
	font-size:16px;
	border-right:1px solid #999;
	margin-right:-1px;
	display:flex;
	flex-direction:column;
	overflow:hidden;
	// border-radius:9px 0 0 9px;

	.k-lpe-alba-menu-stage-outer {
		flex: 0 1 auto;
		border-bottom:1px solid #999!important;
		opacity:0.7;
		// display:flex;
	}
	.k-lpe-alba-menu-stage {
		cursor:pointer;
		padding:8px;
		// font-weight:bold;
		font-size:15px;
		color:#000;
		display:flex;
		.fas, .far { margin-top:-3px; font-size:20px!important; opacity:0.5; }
		.fas.fa-circle-check, .far.fa-circle { color:#222!important; }

		.k-lpe-alba-menu-stage-label {
			flex: 1 1 auto;
		}
	}

	.k-lpe-alba-menu-stage-hovered .k-lpe-alba-menu-stage {
		// text-shadow:0 0 1px #999;
	}

	.k-lpe-alba-menu-stage-available { 
		opacity:1;
		.fas, .far { opacity:1; }
	}
	.k-lpe-alba-menu-stage-open_for_editing {
		opacity:1;
		.fas, .far { opacity:1; }
		font-weight:bold;
	}
	.k-lpe-alba-menu-stage-complete { 
		opacity:1; 
		color:#000;
		.fas, .far { opacity:1; }
		font-weight:bold;
	}
}

.k-lpe-alba-content {
	flex: 1 1 auto;
	border-left:1px solid #999;
	display:flex;
	flex-direction:column;
	background-color:#fff;
	// border-radius:0 9px 9px 0;

	.k-lpe-alba-content-inner {
		flex:1;
		overflow:auto;
		// padding:0px 12px 8px 12px;
		// padding:0 0 8px 0;

	}

	.k-lpe-alba-content-section {
		display:none;
		border-bottom:1px solid #ccc;
		padding:8px 16px 8px 16px;
	}

	.k-lpe-alba-section-header-icon {
		font-size:24px!important;
		width:24px;
		margin:2px 8px 0 0px;
		padding:0;
	}

	.k-lpe-alba-section-header-text {
		font-weight:bold;
		margin-left:4px;
		cursor:pointer;
	}

	.k-lpe-alba-section-summary {
		padding:0px 8px;
		// border-radius:4px;
		// flex: 0 1 600px;
		// max-width:600px;
	}

	.k-lpe-alba-section-summary-empty {
		text-align:center;
		font-size:14px;
		font-style:italic;
	}

	.k-lpe-alba-section-instructions {
		display:none;
		background-color:#f8f8f8;
		padding:0px 8px;
		border-radius:4px;
		// flex: 0 1 600px;
		font-size:14px;
		line-height:18px;
		margin-left:12px;
	}

	.k-lpe-alba-section-editor {
		// display:none;
	}

	.k-lpe-alba-section-buttons {
		display:flex;
		align-items:center;
		margin-top:8px;
		margin-bottom:8px;
	}

	.k-lpe-alba-section-stage-open_for_editing { 
		display:block;
		.k-lpe-alba-section-instructions { display:block; }
		// .k-lpe-alba-section-editor { display:block; }
		.k-lpe-alba-section-summary { display:none; }
	}
	.k-lpe-alba-section-stage-marked_done { 
	}
	.k-lpe-alba-section-stage-complete { 
		display:block;
	}

	.k-lpe-alba-content-section.k-lpe-alba-section-stage-open_for_editing {
		// border-bottom-width:3px;
		// background-color:#eee;
		border-bottom:1px solid #ccc!important;
	}

	// .k-lpe-alba-content-section:first-child { border-top:0; padding-top:0; }
}

// .v-application .green.lighten-5.k-lpe-alba-content-section {
// 		border-color: #ccc!important;
// }

.k-lpe-alba-content-item {
	display:flex;
	align-items:flex-start;
	margin-left:-4px;
	padding-left:4px;
	border-radius:5px;
}

.k-lpe-alba-textarea textarea {
	font-size: 14px;
    line-height: 18px;
}

.k-lpe-alba-saved-indicator {
	background-color:$v-green-darken-3;
	color:#fff;
	font-weight:bold;
	font-size:18px;
	line-height:18px;
	padding:3px 8px;
	border-radius:8px;
	border:4px solid $v-amber-accent-4;
	transition:all 0.2s;
	box-shadow: 0 0 10px rgba(255, 230, 0, 0.8), 0 0 20px rgba(255, 230, 0, 0.6);
}

.k-lpe-dog-icon {
	font-size:1.15em!important;
	animation: lpe-dog-icon-hop 5s infinite;
	color:$v-brown-darken-2!important;
}

@keyframes lpe-dog-icon-hop {
	0% { transform: translateY(0px) scaleX(-1);}
	3% { transform: translateY(-3px) rotate(10deg) scaleX(-1);}
	6% { transform: translateY(0px) scaleX(-1);}
	9% { transform: translateY(0px) scaleX(-1);}
	12% { transform: translateY(-3px) rotate(-10deg) scaleX(-1);}
	15% { transform: translateY(0px) scaleX(-1);}
	100% { transform: translateY(0px) scaleX(-1);}
}

</style>
